import React, {useEffect, useState} from "react";
import Divider from "@mui/material/Divider";

import Input from "../UI/Input";
import Select from "../UI/Select";
import Button from "../UI/Button";
import CallbackItem from "./CallbackItem";
import Loading from "../UI/Loading";
import Modal from "../UI/Modal";

import styles from "./Callbacks.module.css";

import {useForm} from "../Hooks/form-hook";
import Transcription from "./Transcription/Transcription";

const Callbacks = (props) => {
  const [callbacks, setCallbacks] = useState([]);
  const [filteredCallbacks, setFilteredCallbacks] = useState();
  const [selectedCallback, setSelectedCallback] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showTranscript, setShowTranscript] = useState({
    show: false,
    transcript: [],
  });

  const [queueOptions, setQueueOptions] = useState([
    {
      value: "",
      name: "--Please Select--",
    },
  ]);

  useEffect(() => {
    let queues = props.agentQueues?.filter((x) => {
      return x && x !== null;
    });
    if (queues && queues.length > 0) {
      setQueueOptions([
        {
          value: "",
          name: "--Please Select--",
        },
        ...queues,
      ]);
    }
  }, [props.agentQueues]);

  const [outboundFormState, outboundSelectHandler] = useForm(
    {
      queue: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const [searchFormState, searchInputHandler, searchSelectHandler] = useForm(
    {
      sort: {
        value: "",
        isValid: true,
      },
      search: {
        value: "",
        isValid: true,
      },
    },
    true
  );

  const onDetailsSelectHandler = (contact) => {
    setSelectedCallback(contact);
  };

  const makeDirectOutboundCallHandler = (event) => {
    event.preventDefault();
    console.log(`Making Direct Outbound Call!! =========>`);
    props.makeDirectOutboundCall(
      `${selectedCallback.customer_phonenumber}`,
      outboundFormState.inputs.queue.value
    );
    outboundSelectHandler("queue", "", false);
  };

  useEffect(() => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
      },
    };

    setIsLoading(true);
    fetch(`${process.env.REACT_APP_AWS_ENDPOINT}/get-callbacks`, options)
      .then((resp) => resp.json())
      .then((data) => {
        //console.log(`Callback Details =======> ${JSON.stringify(data)}`);

        let assignedCallbacks = data;
        let queues = [];
        if (props.agentQueues) {
          queues = props.agentQueues?.map((x) => {
            if (x && x?.name !== "null") {
              return x.name.toLowerCase();
            }
          });

          console.log(`Agent Queues =======> ${JSON.stringify(queues)}`);

          assignedCallbacks = data.filter((x) =>
            queues?.includes(x.client_name)
          );
        }

        let exists = assignedCallbacks.find(
          (x) => x.ContactId === selectedCallback?.ContactId
        );
        exists && exists.length > 0
          ? setSelectedCallback(selectedCallback)
          : setSelectedCallback(assignedCallbacks[0]);

        assignedCallbacks.sort(function (a, b) {
          return new Date(b.start_time) - new Date(a.start_time);
        });
        setCallbacks(assignedCallbacks);
        setFilteredCallbacks(assignedCallbacks);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(`Error in getting recordings =======> ${error}`);
        setIsLoading(false);
      });
  }, [props.agentQueues]);

  useEffect(() => {
    if (selectedCallback) {
      setShowTranscript({
        show: showTranscript.show,
        transcript: selectedCallback.transcription,
      });
    }
  }, [selectedCallback]);

  const sortedCallbacks = (sortCriteria) => {
    if (filteredCallbacks && filteredCallbacks.length > 0) {
      switch (sortCriteria) {
        case "lastNameAsc":
          filteredCallbacks.sort((a, b) =>
            a.customer_lastname.trim().toLowerCase() >
            b.customer_lastname.trim().toLowerCase()
              ? 1
              : b.customer_lastname.trim().toLowerCase() >
                a.customer_lastname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "lastNameDesc":
          filteredCallbacks.sort((a, b) =>
            a.customer_lastname.trim().toLowerCase() <
            b.customer_lastname.trim().toLowerCase()
              ? 1
              : b.customer_lastname.trim().toLowerCase() <
                a.customer_lastname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "firstNameAsc":
          filteredCallbacks.sort((a, b) =>
            a.customer_firstname.trim().toLowerCase() >
            b.customer_firstname.trim().toLowerCase()
              ? 1
              : b.customer_firstname.trim().toLowerCase() >
                a.customer_firstname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "lastNameDesc":
          filteredCallbacks.sort((a, b) =>
            a.customer_firstname.trim().toLowerCase() <
            b.customer_firstname.trim().toLowerCase()
              ? 1
              : b.customer_firstname.trim().toLowerCase() <
                a.customer_firstname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "cityAsc":
          filteredCallbacks.sort((a, b) =>
            a.customer_city.trim().toLowerCase() >
            b.customer_city.trim().toLowerCase()
              ? 1
              : b.customer_city.trim().toLowerCase() >
                a.customer_city.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "cityDesc":
          filteredCallbacks.sort((a, b) =>
            a.customer_city.trim().toLowerCase() <
            b.customer_city.trim().toLowerCase()
              ? 1
              : b.customer_city.trim().toLowerCase() <
                a.customer_city.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "stateAsc":
          filteredCallbacks.sort((a, b) =>
            a.customer_state.trim().toLowerCase() >
            b.customer_state.trim().toLowerCase()
              ? 1
              : b.customer_state.trim().toLowerCase() >
                a.customer_state.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "stateDesc":
          filteredCallbacks.sort((a, b) =>
            a.customer_state.trim().toLowerCase() <
            b.customer_state.trim().toLowerCase()
              ? 1
              : b.customer_state.trim().toLowerCase() <
                a.customer_state.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "phoneAsc":
          filteredCallbacks.sort((a, b) => {
            return a.customer_phonenumber > b.customer_phonenumber
              ? 1
              : b.customer_phonenumber > a.customer_phonenumber
              ? -1
              : 0;
          });
          break;
        case "phoneDesc":
          filteredCallbacks.sort((a, b) => {
            return a.customer_phonenumber < b.customer_phonenumber
              ? 1
              : b.customer_phonenumber < a.customer_phonenumber
              ? -1
              : 0;
          });
          break;
        case "clientNameAsc":
          filteredCallbacks.sort((a, b) =>
            a.client_name.trim().toLowerCase() >
            b.client_name.trim().toLowerCase()
              ? 1
              : b.client_name.trim().toLowerCase() >
                a.client_name.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "clientNameDesc":
          filteredCallbacks.sort((a, b) =>
            a.client_name.trim().toLowerCase() <
            b.client_name.trim().toLowerCase()
              ? 1
              : b.client_name.trim().toLowerCase() <
                a.client_name.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "startTimeAsc":
          filteredCallbacks.sort(function (a, b) {
            return new Date(b.start_time) - new Date(a.start_time);
          });
          break;
        case "startTimeDesc":
          filteredCallbacks.sort(function (a, b) {
            return new Date(a.start_time) - new Date(b.start_time);
          });
          break;
        default:
          filteredCallbacks.sort(function (a, b) {
            return new Date(a.start_time) - new Date(b.start_time);
          });
          break;
      }
      setFilteredCallbacks(filteredCallbacks);
    } else {
      setFilteredCallbacks(filteredCallbacks);
    }
  };

  const searchedCallbacks = (searchedKeyword) => {
    if (callbacks && callbacks.length > 0) {
      let filtered = callbacks.filter((element) => {
        let partialResult = false;
        if (searchedKeyword.trim().length > 0) {
          if (
            element.customer_firstname
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_lastname
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_phonenumber
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_email
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.client_name
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_comments
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else {
            partialResult = false;
          }
        } else {
          partialResult = true;
        }
        if (partialResult) {
          return true;
        } else {
          return false;
        }
      });
      if (filtered.length > 0) {
        setFilteredCallbacks(filtered);
        if (selectedCallback) {
          let find = filtered.find(
            (x) =>
              x.customer_phonenumber === selectedCallback?.customer_phonenumber
          );
          if (find && find.length > 0) {
            setSelectedCallback(selectedCallback);
          } else {
            setSelectedCallback(filtered[0]);
          }
        } else {
          setSelectedCallback(filtered[0]);
        }
      } else {
        setFilteredCallbacks();
        setSelectedCallback();
      }
    } else {
      setFilteredCallbacks(callbacks);
    }
  };

  console.log(`Show Transcript =======> ${JSON.stringify(showTranscript)}`);

  return (
    <>
      {props?.auth === true && (
        <div className={`${styles.callbacks__Container}`}>
          <Modal
            headerClass={`${styles.callback__Transcript__Modal__Header}`}
            footerClass={`${styles.callback__Transcript__Modal__Footer}`}
            show={showTranscript.show === true}
            onCancel={() =>
              setShowTranscript({
                show: false,
                transcript: showTranscript.transcript,
              })
            }
            className={`${styles.callback__Transcript__Modal}`}
            header={
              <>
                <h2>Transcript</h2>
                <div
                  className={`${styles.callback__Transcript__Modal__Transcript}`}
                >
                  <Transcription transcription={showTranscript.transcript} />
                </div>
              </>
            }
            footer={
              <>
                <div
                  className={`${styles.callback__Transcript__Modal__Footer__Button}`}
                >
                  <Button
                    size="inverse"
                    onClick={() =>
                      setShowTranscript({
                        show: false,
                        transcript: showTranscript.transcript,
                      })
                    }
                  >
                    CLOSE
                  </Button>
                </div>
              </>
            }
          ></Modal>

          <div className={`${styles.callbacks__Container__Header}`}>
            <div className={`${styles.callbacks__Container__Header__Segment}`}>
              <div className={`${styles.callbacks__Container__Header__Filter}`}>
                <img src="/images/search-icon.svg" />
                <Input
                  id="search"
                  element="input"
                  type="text"
                  placeholder="Search"
                  label="NA"
                  validators={[]}
                  onInput={searchInputHandler}
                  onTyped={searchedCallbacks}
                  value={searchFormState.inputs.search.value}
                  valid={searchFormState.inputs.search.isValid}
                />
              </div>
              <div className={`${styles.callbacks__Container__Header__Sort}`}>
                <Select
                  id="sort"
                  options={[
                    {
                      name: "Customer Lastname (Ascending)",
                      value: "lastNameAsc",
                    },
                    {
                      name: "Customer Lastname (Descending)",
                      value: "lastNameDesc",
                    },
                    {
                      name: "Customer Firstname (Ascending)",
                      value: "firstNameAsc",
                    },
                    {
                      name: "Customer Firstname (Descending)",
                      value: "firstNameDesc",
                    },
                    {
                      name: "Customer City (Ascending)",
                      value: "cityAsc",
                    },
                    {
                      name: "Customer City (Descending)",
                      value: "cityDesc",
                    },
                    {
                      name: "Customer State (Ascending)",
                      value: "stateAsc",
                    },
                    {
                      name: "Customer State (Descending)",
                      value: "stateDesc",
                    },
                    {
                      name: "Customer Phonenumber (Ascending)",
                      value: "phoneAsc",
                    },
                    {
                      name: "Customer Phonenumber (Descending)",
                      value: "phoneDesc",
                    },
                    {
                      name: "Project Name (Ascending)",
                      value: "clientNameAsc",
                    },
                    {
                      name: "Project Name (Descending)",
                      value: "clientNameDesc",
                    },
                    {
                      name: "Call Time (Latest)",
                      value: "startTimeAsc",
                    },
                    {
                      name: "Call Time (Oldest)",
                      value: "startTimeDesc",
                    },
                  ]}
                  onSelect={searchSelectHandler}
                  label="NA"
                  returnSelection={sortedCallbacks}
                  value={searchFormState.inputs.sort.value}
                  valid={searchFormState.inputs.sort.isValid}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.callbacks__Container__Body}`}>
            <div className={`${styles.callbacks__ListContainer}`}>
              {filteredCallbacks &&
                filteredCallbacks.length > 0 &&
                filteredCallbacks.map((callback) => (
                  <CallbackItem
                    callback={callback}
                    selectedCallback={selectedCallback}
                    onDetailsSelect={onDetailsSelectHandler}
                    key={`${callback.customer_phonenumber}_${callback.start_time}`}
                  />
                ))}
            </div>
            <div className={`${styles.callbacks__DataContainer}`}>
              {selectedCallback && !isLoading && (
                <div className={`${styles.callbacks__Data__Body}`}>
                  <div className={`${styles.callbacks__Data__Body_RowSegment}`}>
                    <div
                      className={`${styles.callbacks__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedCallback.customer_firstname
                          ? selectedCallback.customer_firstname
                          : "--"}
                      </h3>
                      <h5>First Name</h5>
                    </div>

                    <div
                      className={`${styles.callbacks__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedCallback.customer_lastname
                          ? selectedCallback.customer_lastname
                          : "--"}
                      </h3>
                      <h5>Last Name</h5>
                    </div>
                  </div>
                  <div className={`${styles.callbacks__Data__Body_RowSegment}`}>
                    <div
                      className={`${styles.callbacks__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedCallback.customer_email
                          ? selectedCallback.customer_email
                          : "--"}
                      </h3>
                      <h5>Email</h5>
                    </div>

                    <div
                      className={`${styles.callbacks__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedCallback.customer_comments
                          ? selectedCallback.customer_comments
                          : "--"}
                      </h3>
                      <h5>Comments</h5>
                    </div>
                  </div>
                  <div className={`${styles.callbacks__Data__Body_RowSegment}`}>
                    <div
                      className={`${styles.callbacks__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedCallback.customer_address
                          ? selectedCallback.customer_address
                          : "--"}
                      </h3>
                      <h5>Address</h5>
                    </div>

                    <div
                      className={`${styles.callbacks__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedCallback.customer_city
                          ? selectedCallback.customer_city
                          : "--"}
                      </h3>
                      <h5>City</h5>
                    </div>
                  </div>
                  <div className={`${styles.callbacks__Data__Body_RowSegment}`}>
                    <div
                      className={`${styles.callbacks__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedCallback.customer_state
                          ? selectedCallback.customer_state
                          : "--"}
                      </h3>
                      <h5>State</h5>
                    </div>

                    <div
                      className={`${styles.callbacks__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedCallback.customer_country
                          ? selectedCallback.customer_country
                          : "--"}
                      </h3>
                      <h5>Country</h5>
                    </div>
                  </div>
                </div>
              )}

              {selectedCallback && !isLoading && <Divider variant="middle" />}

              {selectedCallback && !isLoading && (
                <div className={`${styles.callbacks__Data__Recording}`}>
                  {/* {selectedCallback.combinedAudio && (
                    <audio controls controlsList="nodownload">
                      <source src={`${selectedCallback.combinedAudio}`} />
                    </audio>
                  )} */}

                  <div
                    className={`${styles.callbacks__Data__Recording__Transcript}`}
                  >
                    <img
                      src="/images/transcript.svg"
                      onClick={() =>
                        setShowTranscript({
                          show: true,
                          transcript: showTranscript.transcript,
                        })
                      }
                    />
                  </div>
                  <div
                    className={`${styles.callbacks__Data__Recording__Audio}`}
                  >
                    <div
                      className={`${styles.callbacks__Data__Recording__Audio__Element}`}
                    >
                      <h6>Customer</h6>
                      <audio controls controlsList="nodownload">
                        <source src={`${selectedCallback.customer_filename}`} />
                      </audio>
                    </div>

                    <div
                      className={`${styles.callbacks__Data__Recording__Audio__Element}`}
                    >
                      <h6>System</h6>
                      <audio controls controlsList="nodownload">
                        <source src={`${selectedCallback.system_filename}`} />
                      </audio>
                    </div>
                  </div>
                </div>
              )}

              {selectedCallback && !isLoading && <Divider variant="middle" />}
              {selectedCallback && !isLoading && (
                <div className={`${styles.callbacks__Data__Outbound}`}>
                  <h6>Outbound Queue</h6>
                  <form
                    className={`${styles.callbacks__Data__Outbound__Controls}`}
                    onSubmit={makeDirectOutboundCallHandler}
                  >
                    <div
                      className={`${styles.callbacks__Data__Outbound__Controls__Segment}`}
                    >
                      <div
                        className={`${styles.callbacks__Data__Outbound__Controls__Queue}`}
                      >
                        <Select
                          id="queue"
                          label="NA"
                          options={queueOptions}
                          onSelect={outboundSelectHandler}
                          value={outboundFormState.inputs.queue.value}
                          valid={outboundFormState.inputs.queue.isValid}
                        />
                      </div>
                    </div>
                    <div
                      className={`${styles.callbacks__Data__Outbound__Controls__Segment}`}
                    >
                      <Button
                        type="submit"
                        disabled={!outboundFormState.isValid}
                      >
                        CALL
                      </Button>
                    </div>
                  </form>
                </div>
              )}

              {!selectedCallback && !isLoading && (
                <div className={`${styles.callbacks__NoDataContainer}`}>
                  <h2>No Callbacks</h2>
                </div>
              )}

              {isLoading && (
                <div className={`${styles.callbacks__Loading}`}>
                  <Loading />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {props?.auth === false && (
        <div className={`${styles.callbacks__NoAuthContainer}`}>
          <h2>Not Authenticated!</h2>
        </div>
      )}
    </>
  );
};

export default Callbacks;
