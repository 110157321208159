import {useEffect, useState} from "react";

import Loading from "../UI/Loading";

import styles from "./Recording.module.css";
import RecordingItem from "./RecordingItem";

const Recording = (props) => {
  const [queues, setQueues] = useState();
  const [recordings, setRecordings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let queues = props.agentQueues?.map((x) => {
      if (x && x?.name != "null") {
        return x.name.toLowerCase();
      }
    });

    setQueues(queues);

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (queues && queues?.length > 0) {
      setIsLoading(true);
      let recordingsArr = [];
      let promises = [];
      queues.forEach((queue) => {
        if (queue) {
          let promise = new Promise((resolve) => {
            fetch(
              `${process.env.REACT_APP_AWS_ENDPOINT}/recordings/client_name/${queue}`,
              options
            )
              .then((resp) => resp.json())
              .then((data) => {
                console.log(
                  `Recording Data =======> ${JSON.stringify(data.body)}`
                );
                if (data.status_code === 200) {
                  recordingsArr = [...recordingsArr, ...data.body];
                  resolve(data.body);
                }
              })
              .catch((error) => {
                console.error(`Error in getting recordings =======> ${error}`);
              });
          });
          promises.push(promise);
        }
      });

      Promise.all(promises).then((elements) => {
        recordingsArr.sort(function (a, b) {
          return new Date(b.start_time) - new Date(a.start_time);
        });
        setRecordings(recordingsArr);
        setIsLoading(false);
      });

      //   fetch(
      //     `${process.env.REACT_APP_AWS_ENDPOINT}/recordings/client_name/${queues[0]}`,
      //     options
      //   )
      //     .then((resp) => resp.json())
      //     .then((data) => {
      //       console.log(`Recordings ======> ${JSON.stringify(data.body)}`);

      //       if (data.status_code === 200) {
      //         data.body.sort(function (a, b) {
      //           return new Date(b.start_time) - new Date(a.start_time);
      //         });
      //         setRecordings(data.body);
      //       }
      //       setIsLoading(false);
      //     })
      //     .catch((error) => {
      //       console.log(`Error in getting recordings =======> ${error}`);
      //       setIsLoading(false);
      //     });
    }
  }, []);

  console.log(
    `Recording: is Priviledged ==========> ${JSON.stringify(
      props.isPriviledged
    )}`
  );

  return (
    <div className={`${styles.recording__Container}`}>
      {recordings &&
        recordings.length > 0 &&
        !isLoading &&
        props?.auth === true &&
        recordings.map((recording) => (
          <RecordingItem
            recording={recording}
            isPriviledged={props.isPriviledged}
            key={`${recording.recordingUrl}`}
          />
        ))}
      {recordings &&
        recordings.length === 0 &&
        props?.auth === true &&
        !isLoading && (
          <div className={`${styles.recording__NoData}`}>
            <h2>No Recordings</h2>
          </div>
        )}
      {props?.auth === false && (
        <div className={`${styles.recording__NoData}`}>
          <h2>Not Authenticated!</h2>
        </div>
      )}
      {isLoading && (
        <div className={`${styles.recording__Loading}`}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Recording;
