import {useEffect, useState} from "react";
import Divider from "@mui/material/Divider";

import Input from "../UI/Input";
import Select from "../UI/Select";
import Button from "../UI/Button";
import MissedCallItem from "./MissedCallItem";
import Loading from "../UI/Loading";

import styles from "./MissedCalls.module.css";

import {useForm} from "../Hooks/form-hook";

const MissedCalls = (props) => {
  const [missedcalls, setMissedCalls] = useState([]);
  const [filteredMissedCalls, setFilteredMissedCalls] = useState();
  const [selectedMissedCall, setSelectedMissedCall] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [queueOptions, setQueueOptions] = useState([
    {
      value: "",
      name: "--Please Select--",
    },
  ]);

  useEffect(() => {
    let queues = props.agentQueues?.filter((x) => {
      return x && x !== null;
    });
    if (queues && queues.length > 0) {
      setQueueOptions([
        {
          value: "",
          name: "--Please Select--",
        },
        ...queues,
      ]);
    }
  }, [props.agentQueues]);

  const [outboundFormState, outboundSelectHandler] = useForm(
    {
      queue: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const [searchFormState, searchInputHandler, searchSelectHandler] = useForm(
    {
      sort: {
        value: "",
        isValid: true,
      },
      search: {
        value: "",
        isValid: true,
      },
    },
    true
  );

  const onDetailsSelectHandler = (contact) => {
    setSelectedMissedCall(contact);
  };

  const makeDirectOutboundCallHandler = (event) => {
    event.preventDefault();
    console.log(`Making Direct Outbound Call!! =========>`);
    props.makeDirectOutboundCall(
      `${selectedMissedCall.customer_phonenumber}`,
      outboundFormState.inputs.queue.value
    );
    outboundSelectHandler("queue", "", false);
  };

  useEffect(() => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
      },
    };

    setIsLoading(true);
    fetch(`${process.env.REACT_APP_AWS_ENDPOINT}/get-missedcalls`, options)
      .then((resp) => resp.json())
      .then((data) => {
        console.log(`Missed Call Details =======> ${JSON.stringify(data)}`);

        let assignedMissedCalls = data;
        let queues = [];
        if (props.agentQueues) {
          queues = props.agentQueues?.map((x) => {
            if (x && x?.name !== "null") {
              return x.name.toLowerCase();
            }
          });

          console.log(`Agent Queues =======> ${JSON.stringify(queues)}`);

          assignedMissedCalls = data.filter((x) =>
            queues?.includes(x.client_name)
          );
        }

        let exists = assignedMissedCalls.find(
          (x) => x.ContactId === selectedMissedCall?.ContactId
        );
        exists && exists.length > 0
          ? setSelectedMissedCall(selectedMissedCall)
          : setSelectedMissedCall(assignedMissedCalls[0]);

        assignedMissedCalls.sort(function (a, b) {
          return new Date(b.start_time) - new Date(a.start_time);
        });
        setMissedCalls(assignedMissedCalls);
        setFilteredMissedCalls(assignedMissedCalls);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(`Error in getting recordings =======> ${error}`);
        setIsLoading(false);
      });
  }, [props.agentQueues]);

  const sortedMissedCalls = (sortCriteria) => {
    if (filteredMissedCalls && filteredMissedCalls.length > 0) {
      switch (sortCriteria) {
        case "lastNameAsc":
          filteredMissedCalls.sort((a, b) =>
            a.customer_lastname.trim().toLowerCase() >
            b.customer_lastname.trim().toLowerCase()
              ? 1
              : b.customer_lastname.trim().toLowerCase() >
                a.customer_lastname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "lastNameDesc":
          filteredMissedCalls.sort((a, b) =>
            a.customer_lastname.trim().toLowerCase() <
            b.customer_lastname.trim().toLowerCase()
              ? 1
              : b.customer_lastname.trim().toLowerCase() <
                a.customer_lastname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "firstNameAsc":
          filteredMissedCalls.sort((a, b) =>
            a.customer_firstname.trim().toLowerCase() >
            b.customer_firstname.trim().toLowerCase()
              ? 1
              : b.customer_firstname.trim().toLowerCase() >
                a.customer_firstname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "lastNameDesc":
          filteredMissedCalls.sort((a, b) =>
            a.customer_firstname.trim().toLowerCase() <
            b.customer_firstname.trim().toLowerCase()
              ? 1
              : b.customer_firstname.trim().toLowerCase() <
                a.customer_firstname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "cityAsc":
          filteredMissedCalls.sort((a, b) =>
            a.customer_city.trim().toLowerCase() >
            b.customer_city.trim().toLowerCase()
              ? 1
              : b.customer_city.trim().toLowerCase() >
                a.customer_city.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "cityDesc":
          filteredMissedCalls.sort((a, b) =>
            a.customer_city.trim().toLowerCase() <
            b.customer_city.trim().toLowerCase()
              ? 1
              : b.customer_city.trim().toLowerCase() <
                a.customer_city.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "stateAsc":
          filteredMissedCalls.sort((a, b) =>
            a.customer_state.trim().toLowerCase() >
            b.customer_state.trim().toLowerCase()
              ? 1
              : b.customer_state.trim().toLowerCase() >
                a.customer_state.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "stateDesc":
          filteredMissedCalls.sort((a, b) =>
            a.customer_state.trim().toLowerCase() <
            b.customer_state.trim().toLowerCase()
              ? 1
              : b.customer_state.trim().toLowerCase() <
                a.customer_state.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "phoneAsc":
          filteredMissedCalls.sort((a, b) => {
            return a.customer_phonenumber > b.customer_phonenumber
              ? 1
              : b.customer_phonenumber > a.customer_phonenumber
              ? -1
              : 0;
          });
          break;
        case "phoneDesc":
          filteredMissedCalls.sort((a, b) => {
            return a.customer_phonenumber < b.customer_phonenumber
              ? 1
              : b.customer_phonenumber < a.customer_phonenumber
              ? -1
              : 0;
          });
          break;
        case "clientNameAsc":
          filteredMissedCalls.sort((a, b) =>
            a.client_name.trim().toLowerCase() >
            b.client_name.trim().toLowerCase()
              ? 1
              : b.client_name.trim().toLowerCase() >
                a.client_name.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "clientNameDesc":
          filteredMissedCalls.sort((a, b) =>
            a.client_name.trim().toLowerCase() <
            b.client_name.trim().toLowerCase()
              ? 1
              : b.client_name.trim().toLowerCase() <
                a.client_name.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "startTimeAsc":
          filteredMissedCalls.sort(function (a, b) {
            return new Date(b.start_time) - new Date(a.start_time);
          });
          break;
        case "startTimeDesc":
          filteredMissedCalls.sort(function (a, b) {
            return new Date(a.start_time) - new Date(b.start_time);
          });
          break;
        default:
          filteredMissedCalls.sort(function (a, b) {
            return new Date(a.start_time) - new Date(b.start_time);
          });
          break;
      }
      setFilteredMissedCalls(filteredMissedCalls);
    } else {
      setFilteredMissedCalls(filteredMissedCalls);
    }
  };

  const searchedMissedCalls = (searchedKeyword) => {
    if (missedcalls && missedcalls.length > 0) {
      let filtered = missedcalls.filter((element) => {
        let partialResult = false;
        if (searchedKeyword.trim().length > 0) {
          if (
            element.customer_firstname
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_lastname
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_phonenumber
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_email
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.client_name
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_comments
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else {
            partialResult = false;
          }
        } else {
          partialResult = true;
        }
        if (partialResult) {
          return true;
        } else {
          return false;
        }
      });
      if (filtered.length > 0) {
        setFilteredMissedCalls(filtered);
        if (selectedMissedCall) {
          let find = filtered.find(
            (x) =>
              x.customer_phonenumber ===
              selectedMissedCall?.customer_phonenumber
          );
          if (find && find.length > 0) {
            setSelectedMissedCall(selectedMissedCall);
          } else {
            setSelectedMissedCall(filtered[0]);
          }
        } else {
          setSelectedMissedCall(filtered[0]);
        }
      } else {
        setFilteredMissedCalls();
        setSelectedMissedCall();
      }
    } else {
      setFilteredMissedCalls(missedcalls);
    }
  };

  return (
    <>
      {props?.auth === true && (
        <div className={`${styles.missedcalls__Container}`}>
          <div className={`${styles.missedcalls__Container__Header}`}>
            <div
              className={`${styles.missedcalls__Container__Header__Segment}`}
            >
              <div
                className={`${styles.missedcalls__Container__Header__Filter}`}
              >
                <img src="/images/search-icon.svg" />
                <Input
                  id="search"
                  element="input"
                  type="text"
                  placeholder="Search"
                  label="NA"
                  validators={[]}
                  onInput={searchInputHandler}
                  onTyped={searchedMissedCalls}
                  value={searchFormState.inputs.search.value}
                  valid={searchFormState.inputs.search.isValid}
                />
              </div>
              <div className={`${styles.missedcalls__Container__Header__Sort}`}>
                <Select
                  id="sort"
                  options={[
                    {
                      name: "Customer Lastname (Ascending)",
                      value: "lastNameAsc",
                    },
                    {
                      name: "Customer Lastname (Descending)",
                      value: "lastNameDesc",
                    },
                    {
                      name: "Customer Firstname (Ascending)",
                      value: "firstNameAsc",
                    },
                    {
                      name: "Customer Firstname (Descending)",
                      value: "firstNameDesc",
                    },
                    {
                      name: "Customer City (Ascending)",
                      value: "cityAsc",
                    },
                    {
                      name: "Customer City (Descending)",
                      value: "cityDesc",
                    },
                    {
                      name: "Customer State (Ascending)",
                      value: "stateAsc",
                    },
                    {
                      name: "Customer State (Descending)",
                      value: "stateDesc",
                    },
                    {
                      name: "Customer Phonenumber (Ascending)",
                      value: "phoneAsc",
                    },
                    {
                      name: "Customer Phonenumber (Descending)",
                      value: "phoneDesc",
                    },
                    {
                      name: "Project Name (Ascending)",
                      value: "clientNameAsc",
                    },
                    {
                      name: "Project Name (Descending)",
                      value: "clientNameDesc",
                    },
                    {
                      name: "Call Time (Latest)",
                      value: "startTimeAsc",
                    },
                    {
                      name: "Call Time (Oldest)",
                      value: "startTimeDesc",
                    },
                  ]}
                  onSelect={searchSelectHandler}
                  label="NA"
                  returnSelection={sortedMissedCalls}
                  value={searchFormState.inputs.sort.value}
                  valid={searchFormState.inputs.sort.isValid}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.missedcalls__Container__Body}`}>
            <div className={`${styles.missedcalls__ListContainer}`}>
              {filteredMissedCalls &&
                filteredMissedCalls.length > 0 &&
                filteredMissedCalls.map((missedcall) => (
                  <MissedCallItem
                    missedcall={missedcall}
                    selectedMissedCall={selectedMissedCall}
                    onDetailsSelect={onDetailsSelectHandler}
                    key={missedcall.customer_phonenumber}
                  />
                ))}
            </div>
            <div className={`${styles.missedcalls__DataContainer}`}>
              {selectedMissedCall && !isLoading && (
                <div className={`${styles.missedcalls__Data__Body}`}>
                  <div
                    className={`${styles.missedcalls__Data__Body_RowSegment}`}
                  >
                    <div
                      className={`${styles.missedcalls__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedMissedCall.customer_firstname
                          ? selectedMissedCall.customer_firstname
                          : "--"}
                      </h3>
                      <h5>First Name</h5>
                    </div>

                    <div
                      className={`${styles.missedcalls__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedMissedCall.customer_lastname
                          ? selectedMissedCall.customer_lastname
                          : "--"}
                      </h3>
                      <h5>Last Name</h5>
                    </div>
                  </div>
                  <div
                    className={`${styles.missedcalls__Data__Body_RowSegment}`}
                  >
                    <div
                      className={`${styles.missedcalls__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedMissedCall.customer_email
                          ? selectedMissedCall.customer_email
                          : "--"}
                      </h3>
                      <h5>Email</h5>
                    </div>

                    <div
                      className={`${styles.missedcalls__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedMissedCall.customer_comments
                          ? selectedMissedCall.customer_comments
                          : "--"}
                      </h3>
                      <h5>Comments</h5>
                    </div>
                  </div>
                  <div
                    className={`${styles.missedcalls__Data__Body_RowSegment}`}
                  >
                    <div
                      className={`${styles.missedcalls__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedMissedCall.customer_address
                          ? selectedMissedCall.customer_address
                          : "--"}
                      </h3>
                      <h5>Address</h5>
                    </div>

                    <div
                      className={`${styles.missedcalls__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedMissedCall.customer_city
                          ? selectedMissedCall.customer_city
                          : "--"}
                      </h3>
                      <h5>City</h5>
                    </div>
                  </div>
                  <div
                    className={`${styles.missedcalls__Data__Body_RowSegment}`}
                  >
                    <div
                      className={`${styles.missedcalls__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedMissedCall.customer_state
                          ? selectedMissedCall.customer_state
                          : "--"}
                      </h3>
                      <h5>State</h5>
                    </div>

                    <div
                      className={`${styles.missedcalls__Data__Body_RowSegment__Data}`}
                    >
                      <h3>
                        {selectedMissedCall.customer_country
                          ? selectedMissedCall.customer_country
                          : "--"}
                      </h3>
                      <h5>Country</h5>
                    </div>
                  </div>
                </div>
              )}

              {selectedMissedCall && !isLoading && <Divider variant="middle" />}
              {selectedMissedCall && !isLoading && (
                <div className={`${styles.missedcalls__Data__Outbound}`}>
                  <form
                    className={`${styles.missedcalls__Data__Outbound__Controls}`}
                    onSubmit={makeDirectOutboundCallHandler}
                  >
                    <div
                      className={`${styles.missedcalls__Data__Outbound__Controls__Segment}`}
                    >
                      <div
                        className={`${styles.missedcalls__Data__Outbound__Controls__Queue}`}
                      >
                        <Select
                          id="queue"
                          label="Outbound Queue"
                          options={queueOptions}
                          onSelect={outboundSelectHandler}
                          value={outboundFormState.inputs.queue.value}
                          valid={outboundFormState.inputs.queue.isValid}
                        />
                      </div>
                    </div>
                    <div
                      className={`${styles.missedcalls__Data__Outbound__Controls__Segment}`}
                    >
                      <Button
                        type="submit"
                        disabled={!outboundFormState.isValid}
                      >
                        CALL
                      </Button>
                    </div>
                  </form>
                </div>
              )}

              {!selectedMissedCall && !isLoading && (
                <div className={`${styles.missedcalls__NoDataContainer}`}>
                  <h2>No Missed Calls</h2>
                </div>
              )}

              {isLoading && (
                <div className={`${styles.missedcalls__Loading}`}>
                  <Loading />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {props?.auth === false && (
        <div className={`${styles.missedcalls__NoAuthContainer}`}>
          <h2>Not Authenticated!</h2>
        </div>
      )}
    </>
  );
};

export default MissedCalls;
