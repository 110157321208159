import React from "react";
import moment from "moment-timezone";

import styles from "./CallbackItem.module.css";

const CallbackItem = (props) => {
  return (
    <div
      className={
        props.selectedCallback?.ContactId === props.callback?.ContactId
          ? `${styles.callbackitem__Container__Selected}`
          : `${styles.callbackitem__Container}`
      }
      onClick={() => {
        props.onDetailsSelect(props.callback);
      }}
    >
      <div
        className={`${styles.callbackitem__Segment__Column}`}
        style={{width: "45%"}}
      >
        <div className={`${styles.callbackitem__Segment}`}>
          <img src="/images/mobile-icon.svg" />
          <h6>{props.callback.customer_phonenumber}</h6>
        </div>
        <div className={`${styles.callbackitem__Segment}`}>
          <img
            src="/images/young-businessman-icon.svg"
            style={{paddingLeft: "0.15rem"}}
          />
          <h6>
            {props.callback.customer_firstname}&nbsp;
            {props.callback.customer_lastname}
          </h6>
        </div>
      </div>

      <div
        className={`${styles.callbackitem__Segment__Column}`}
        style={{width: "55%"}}
      >
        <div className={`${styles.callbackitem__Segment}`}>
          <h5>
            {moment
              .tz(
                new Date(props.callback.start_time),
                process.env.REACT_APP_TIMEZONE
              )
              .format("Do MMM YYYY hh:mm:ss A")}
          </h5>
        </div>
        <div className={`${styles.callbackitem__Segment}`}>
          <img src="/images/random-icon-light.svg" />
          <h5>{props.callback.client_name}</h5>
        </div>
      </div>
    </div>
  );
};

export default CallbackItem;
