import React from "react";
import moment from "moment-timezone";

import styles from "./CustomerJourney.module.css";

const CustomerJourney = (props) => {
  return (
    <div className={`${styles.customerjourney__Container}`}>
      <img src="/images/mobile-icon.svg" />
      <div className={`${styles.customerjourney__CallTime}`}>
        <h6>
          {moment
            .tz(
              new Date(props.customerJourney.start_time),
              process.env.REACT_APP_TIMEZONE
            )
            .format("hh:mm:ss A")}
        </h6>
        <h5>
          {moment
            .tz(
              new Date(props.customerJourney.start_time),
              process.env.REACT_APP_TIMEZONE
            )
            .format("Do MMM YYYY")}
        </h5>
      </div>
    </div>
  );
};

export default CustomerJourney;
