import React from "react";
import TranscriptionItem from "./TranscriptionItem";

import styles from "./Transcription.module.css";

const Transcription = (props) => {
  return (
    <div className={`${styles.transcription__Container}`}>
      {props.transcription &&
        props.transcription.map((transcript) => (
          <TranscriptionItem transcript={transcript} />
        ))}
    </div>
  );
};

export default Transcription;
