import React, {useState} from "react";
import moment from "moment-timezone";

import styles from "./VoicemailItem.module.css";

import Button from "../UI/Button";
import Modal from "../UI/Modal";

const VoicemailItem = (props) => {
  const [showTranscript, setShowTranscript] = useState({
    show: false,
    transcript: "NA",
  });

  const showTranscriptHandler = (transcription) => {
    setShowTranscript({
      show: true,
      transcript: transcription,
    });
  };

  const closeTranscriptHandler = () => {};

  return (
    <div className={`${styles.voicemailitem__Container}`}>
      <Modal
        show={showTranscript.show === true}
        onCancel={() =>
          setShowTranscript({
            show: false,
            transcript: "NA",
          })
        }
        className={`${styles.voicemail__Transcript__Modal}`}
        header={
          <div className={`${styles.voicemail__Transcript__Modal__Header}`}>
            <h2>Transcript</h2>
            <div
              className={`${styles.voicemail__Transcript__Modal__Voicemail}`}
            >
              <h3>{showTranscript.transcript}</h3>
            </div>
          </div>
        }
        footer={
          <div className={`${styles.voicemail__Transcript__Modal__Footer}`}>
            <div
              className={`${styles.voicemail__Transcript__Modal__Footer__Button}`}
            >
              <Button
                size="inverse"
                onClick={() =>
                  setShowTranscript({
                    show: false,
                    transcript: "NA",
                  })
                }
              >
                CLOSE
              </Button>
            </div>
          </div>
        }
      ></Modal>

      <div className={`${styles.voicemailitem__Customer}`}>
        <div className={`${styles.voicemailitem__CustomerPhone}`}>
          <img src="/images/mobile-icon.svg" />
          <h6>{props.voicemail.customer_phonenumber}</h6>
        </div>
        <div className={`${styles.voicemailitem__CustomerName}`}>
          <h6>
            {props.voicemail.customer_firstname}&nbsp;
            {props.voicemail.customer_lastname}
          </h6>
        </div>
      </div>
      <div className={`${styles.voicemailitem__CallTime}`}>
        <h6>
          {moment
            .tz(
              new Date(props.voicemail.start_time),
              process.env.REACT_APP_TIMEZONE
            )
            .format("hh:mm:ss A")}
        </h6>
        <h5>
          {moment
            .tz(
              new Date(props.voicemail.start_time),
              process.env.REACT_APP_TIMEZONE
            )
            .format("Do MMM YYYY")}
        </h5>
      </div>
      <div className={`${styles.voicemailitem__Customer}`}>
        <h6>{props.voicemail.client_name?.toUpperCase()}</h6>
      </div>
      <div className={`${styles.voicemailitem__Recording}`}>
        <audio controls controlsList="nodownload">
          <source src={props.voicemail.recordingUrl} />
        </audio>
      </div>

      <div className={`${styles.voicemailitem__Download}`}>
        <img
          src="/images/transcript.svg"
          onClick={() =>
            showTranscriptHandler(
              props.voicemail.transcription
                ? props.voicemail.transcription
                : "NA"
            )
          }
        />
        {props.isPriviledged && (
          <a href={props.voicemail.recordingUrl} download target="_blank">
            <img src="/images/download.svg" />
          </a>
        )}
      </div>
    </div>
  );
};

export default VoicemailItem;
