import { useReducer, useCallback } from 'react';


const formReducer = (state, action) => {
    switch (action.type) {
        case 'INPUT_CHANGE':
            let formIsValid = true;
            for (const inputId in state.inputs) {
                if (!state.inputs[inputId]) {
                    continue;
                }
                if (inputId === action.inputId) {
                    formIsValid = formIsValid && action.isValid;
                } else {
                    formIsValid = formIsValid && state.inputs[inputId].isValid;
                }
                // console.log(`Form State =====> ${inputId} ${state.inputs[inputId].isValid}`);
            }
            // console.log(`============================================`);
            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    [action.inputId]: { value: action.value, isValid: action.isValid }
                },
                isValid: formIsValid
            };
        case 'DISABLE_FORM':
            return {
                inputs: action.inputs,
                isValid: action.formIsValid,
                isFormDisabled: action.isFormDisabled
            };
        default:
            return state;
    }
};

export const useForm = (initialInputs, initialFormValidity) => {
    const [formState, dispatch] = useReducer(formReducer, {
        inputs: initialInputs,
        isValid: initialFormValidity,
    });

    const inputHandler = useCallback((id, value, isValid) => {

        dispatch({
            type: 'INPUT_CHANGE',
            value: value,
            isValid: isValid,
            inputId: id
        });
        // console.log(`Value =====> ${value}`);
        // console.log(`Is Valid =====> ${isValid}`);
    }, []);

    const selectHandler = useCallback((id, value, isValid) => {
        // console.log(`Select Handler =====> ${value} ${isValid}`);
        dispatch({
            type: 'INPUT_CHANGE',
            value: value,
            isValid: isValid,
            inputId: id
        });
        // console.log(`Value =====> ${value}`);
        // console.log(`Is Valid =====> ${isValid}`);
    }, []);

    const disableForm = useCallback((inputData, formValidity, isFormDisabled) => {
        dispatch({
            type: 'DISABLE_FORM',
            inputs: inputData,
            formIsValid: formValidity,
            isFormDisabled: isFormDisabled
        });
        // console.log(`Value =====> ${value}`);
        // console.log(`Is Valid =====> ${isValid}`);
    }, []);

    return [formState, inputHandler, selectHandler, disableForm];
}

