import React from "react";
import moment from "moment-timezone";

import styles from "./MissedCallItem.module.css";

const MissedCallItem = (props) => {
  return (
    <div
      className={
        props.selectedMissedCall?.ContactId === props.missedcall?.ContactId
          ? `${styles.missedcallitem_Container__Selected}`
          : `${styles.missedcallitem_Container}`
      }
      onClick={() => {
        props.onDetailsSelect(props.missedcall);
      }}
    >
      <div
        className={`${styles.missedcallitem_Segment__Column}`}
        style={{width: "45%"}}
      >
        <div className={`${styles.missedcallitem_Segment}`}>
          <img src="/images/mobile-icon.svg" />
          <h6>{props.missedcall.customer_phonenumber}</h6>
        </div>
        <div className={`${styles.missedcallitem_Segment}`}>
          <img
            src="/images/young-businessman-icon.svg"
            style={{paddingLeft: "0.15rem"}}
          />
          <h6>
            {props.missedcall.customer_firstname}&nbsp;
            {props.missedcall.customer_lastname}
          </h6>
        </div>
      </div>

      <div
        className={`${styles.missedcallitem_Segment__Column}`}
        style={{width: "55%"}}
      >
        <div className={`${styles.missedcallitem_Segment}`}>
          <h5>
            {moment
              .tz(
                new Date(props.missedcall.start_time),
                process.env.REACT_APP_TIMEZONE
              )
              .format("Do MMM YYYY hh:mm:ss A")}
          </h5>
        </div>
        <div className={`${styles.missedcallitem_Segment}`}>
          <img src="/images/random-icon-light.svg" />
          <h5>{props.missedcall.client_name}</h5>
        </div>
      </div>
    </div>
  );
};

export default MissedCallItem;
