import styles from "./Header.module.css";

const Header = (props) => {
  return (
    <header className={`${styles.App_header}`}>
      <div className={`${styles.App_header__Image}`}>
        <img src="images/ppro_logo.jpg" alt="logo" />
      </div>
      <div className={`${styles.App_header__Agent}`}>
        <div className={`${styles.App_header__Agent__Avatar}`}>
          <img src="images/person.svg" alt="avatar" />
        </div>
        <div className={`${styles.App_header__Agent__Info}`}>
          {props.agentInfo && <h5>{props.agentInfo.agentName}</h5>}
          {props.agentInfo && (
            <h6
              style={{
                color:
                  props.agentInfo.agentState.type === "routable"
                    ? "#00bfa6"
                    : "#b9b9b9",
              }}>
              {props.agentInfo.agentState.name}
            </h6>
          )}
          {!props.agentInfo && <h6>Unknown</h6>}
        </div>
      </div>
    </header>
  );
};

export default Header;
