import React from "react";

import styles from "./ContactItem.module.css";

const ContactItem = (props) => {
  //   console.log(
  //     `Selected Contact ======> ${JSON.stringify(props.selectedContact)}`
  //   );

  return (
    <div
      className={
        props.selectedContact?.customer_phonenumber ===
        props.contact?.customer_phonenumber
          ? `${styles.contactitem_Container__Selected}`
          : `${styles.contactitem_Container}`
      }
      onClick={() => {
        props.onDetailsSelect(props.contact);
      }}
    >
      <div className={`${styles.contactitem_Segment}`}>
        <img src="/images/mobile-icon.svg" />
        <h6>+{props.contact.customer_phonenumber}</h6>
      </div>
      <div className={`${styles.contactitem_Segment}`}>
        <img
          src="/images/young-businessman-icon.svg"
          style={{paddingLeft: "0.15rem"}}
        />
        <h6>
          {props.contact.customer_firstname}&nbsp;
          {props.contact.customer_lastname}
        </h6>
      </div>
    </div>
  );
};

export default ContactItem;
