import React, {useState, useEffect} from "react";

import "./Select.css";

const Select = (props) => {
  const [selectState, setSelectState] = useState({
    value: props.value,
    isValid: props.valid,
  });

  const {id, onSelect} = props;
  const {value, isValid} = selectState;

  useEffect(() => {
    onSelect(id, value, isValid);
  }, [id, value, isValid, onSelect]);

  const changeHandler = (event) => {
    setSelectState({
      value: event.target.value,
      isValid: true,
    });
    if (props.returnSelection) {
      props.returnSelection(event.target.value);
    }
  };

  return (
    <div
      className={`form-control ${props.classStyle} ${props.containerClass} ${
        props.isDisabled && "form-control--disabled"
      }`}
    >
      <label
        htmlFor={props.id}
        style={props.label === "NA" ? {display: "none"} : {}}
      >
        {props.label}
      </label>
      <select
        id={props.id}
        onChange={changeHandler}
        disabled={props.isDisabled}
        value={props.value}
        defaultValue={props.defaultValue}
      >
        {props.optionsGroup ? (
          <optgroup label={props.optionsGroup}>
            {props.options.map((option) => (
              <option
                value={option.value}
                key={option.value}
                style={{color: "#444", fontSize: "12px"}}
              >
                {option.name}
              </option>
            ))}
          </optgroup>
        ) : (
          props.options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.name}
            </option>
          ))
        )}
        {props.additionalOptions && (
          <optgroup label={props.additionalOptionsGroup}>
            {props.additionalOptions.map((additionalOption) => (
              <option
                value={additionalOption.value}
                key={additionalOption.value}
              >
                {additionalOption.name}
              </option>
            ))}
          </optgroup>
        )}
      </select>
    </div>
  );
};

export default Select;
