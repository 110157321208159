import React from "react";
import moment from "moment-timezone";

import styles from "./RecordingItem.module.css";

const RecordingItem = (props) => {
  return (
    <div className={`${styles.recordingitem__Container}`}>
      <div className={`${styles.recordingitem__Customer}`}>
        <div className={`${styles.recordingitem__CustomerPhone}`}>
          <img src="/images/mobile-icon.svg" />
          <h6>{props.recording.customer_phonenumber}</h6>
        </div>
        <div className={`${styles.recordingitem__CustomerName}`}>
          <h6>
            {props.recording.customer_firstname}&nbsp;
            {props.recording.customer_lastname}
          </h6>
        </div>
      </div>
      <div className={`${styles.recordingitem__CallTime}`}>
        <h6>
          {moment
            .tz(
              new Date(props.recording.start_time),
              process.env.REACT_APP_TIMEZONE
            )
            .format("hh:mm:ss A")}
        </h6>
        <h5>
          {moment
            .tz(
              new Date(props.recording.start_time),
              process.env.REACT_APP_TIMEZONE
            )
            .format("Do MMM YYYY")}
        </h5>
      </div>
      <div className={`${styles.recordingitem__Customer}`}>
        <h6>{props.recording.client_name?.toUpperCase()}</h6>
      </div>
      <div className={`${styles.recordingitem__Recording}`}>
        <audio controls controlsList="nodownload">
          <source src={props.recording.recordingUrl} />
        </audio>
      </div>
      {props.isPriviledged && (
        <div className={`${styles.recordingitem__Download}`}>
          <a href={props.recording.recordingUrl} download target="_blank">
            <img src="/images/download.svg" />
          </a>
        </div>
      )}
    </div>
  );
};

export default RecordingItem;
