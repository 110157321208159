import React, {useState} from "react";
import {BrowserRouter} from "react-router-dom";

import CCPPanel from "./components/CCPPanel/CCPPanel";
import Header from "./components/Header/Header";

import "./App.css";

function App() {
  const [agentInfo, setAgentInfo] = useState();

  const agentInfoHandler = (agent) => {
    setAgentInfo(agent);
  };

  return (
    <div className="App">
      <Header agentInfo={agentInfo} />
      <section className="App_section">
        <BrowserRouter>
          <CCPPanel agentInfo={agentInfoHandler} />
        </BrowserRouter>
      </section>
    </div>
  );
}

export default App;
