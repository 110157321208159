import React, {useEffect, useState} from "react";
import Divider from "@mui/material/Divider";

import Loading from "../UI/Loading";
import Input from "../UI/Input";
import Select from "../UI/Select";
import Button from "../UI/Button";

import styles from "./Dashboard.module.css";
import CustomerJourney from "./CustomerJourney";

import {useForm} from "../Hooks/form-hook";

import {
  VALIDATOR_NUMBERS,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../UI/util/validators";

const Dashboard = (props) => {
  const [customerJourney, setCustomerJourney] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [queueOptions, setQueueOptions] = useState([
    {
      value: "",
      name: "--Please Select--",
    },
  ]);
  const [countryOptions, setCountryOptions] = useState([
    {
      value: "+52",
      name: "+52 (Mexico)",
    },
    {
      value: "+1",
      name: "+1 (US & Canada)",
    },
  ]);

  const [outboundFormState, outboundInputHandler, outboundSelectHandler] =
    useForm(
      {
        queue: {
          value: "",
          isValid: false,
        },
        country: {
          value: "+52",
          isValid: true,
        },
        phonenumber: {
          value: "",
          isValid: false,
        },
      },
      false
    );

  const [formState, inputHandler, selectHandler] = useForm(
    {
      firstname: {
        value: props.contactAttributes?.customer_firstname,
        isValid: true,
      },
      lastname: {
        value: props.contactAttributes?.customer_lastname,
        isValid: true,
      },
      email: {
        value: props.contactAttributes?.customer_email,
        isValid: true,
      },
      address: {
        value: props.contactAttributes?.customer_address,
        isValid: true,
      },
      city: {
        value: props.contactAttributes?.customer_city,
        isValid: true,
      },
      state: {
        value: props.contactAttributes?.customer_state,
        isValid: true,
      },
      country: {
        value: props.contactAttributes?.customer_country,
        isValid: true,
      },
      comments: {
        value: props.contactAttributes?.customer_comments,
        isValid: true,
      },
    },
    true
  );

  useEffect(() => {
    let queues = props.agentQueues?.filter((x) => {
      return x && x !== null;
    });
    if (queues && queues.length > 0) {
      setQueueOptions([
        {
          value: "",
          name: "--Please Select--",
        },
        ...queues,
      ]);
    }
  }, [props.agentQueues]);

  useEffect(() => {
    inputHandler(
      "firstname",
      props.contactAttributes?.customer_firstname
        ? props.contactAttributes?.customer_firstname
        : "--",
      true
    );
    inputHandler(
      "lastname",
      props.contactAttributes?.customer_lastname
        ? props.contactAttributes?.customer_lastname
        : "--",
      true
    );
    inputHandler(
      "email",
      props.contactAttributes?.customer_email
        ? props.contactAttributes?.customer_email
        : "--",
      true
    );
    inputHandler(
      "address",
      props.contactAttributes?.customer_address
        ? props.contactAttributes?.customer_address
        : "--",
      true
    );
    inputHandler(
      "city",
      props.contactAttributes?.customer_city
        ? props.contactAttributes?.customer_city
        : "--",
      true
    );
    inputHandler(
      "state",
      props.contactAttributes?.customer_state
        ? props.contactAttributes?.customer_state
        : "--",
      true
    );
    inputHandler(
      "country",
      props.contactAttributes?.customer_country
        ? props.contactAttributes?.customer_country
        : "--",
      true
    );
    inputHandler(
      "comments",
      props.contactAttributes?.customer_comments
        ? props.contactAttributes?.customer_comments
        : "--",
      true
    );
  }, [props.contactAttributes?.customer_phonenumber]);

  useEffect(() => {
    if (props.contactAttributes?.customer_phonenumber) {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      setIsLoading(true);
      fetch(
        `${process.env.REACT_APP_AWS_ENDPOINT}/recordings/phonenumber/${props.contactAttributes?.customer_phonenumber}`,
        options
      )
        .then((resp) => resp.json())
        .then((data) => {
          console.log(`Contacts ======> ${JSON.stringify(data.body)}`);

          if (data.status_code === 200) {
            data.body.sort(function (a, b) {
              return new Date(b.start_time) - new Date(a.start_time);
            });
            setCustomerJourney(data.body);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(`Error in getting recordings =======> ${error}`);
          setIsLoading(false);
        });
    } else {
      setCustomerJourney([]);
    }
  }, [props.contactAttributes?.customer_phonenumber]);

  const updateCustomerData = () => {
    console.log(
      `Customer Phone Number ======> ${props.contactAttributes?.customer_phonenumber?.replace(
        "+",
        ""
      )}`
    );
    console.log(
      `Customer Firstname ======> ${formState.inputs.firstname.value}`
    );
    console.log(`Customer Lastname ======> ${formState.inputs.lastname.value}`);
    console.log(`Customer Email ======> ${formState.inputs.email.value}`);
    console.log(`Customer Address ======> ${formState.inputs.address.value}`);
    console.log(`Customer City ======> ${formState.inputs.city.value}`);
    console.log(`Customer State ======> ${formState.inputs.state.value}`);
    console.log(`Customer Country ======> ${formState.inputs.country.value}`);
    console.log(`Customer Comments ======> ${formState.inputs.comments.value}`);

    const options = {
      method: "PATCH",
      body: JSON.stringify({
        customer_phonenumber:
          props.contactAttributes?.customer_phonenumber?.replace("+", ""),
        customer_firstname: formState.inputs.firstname.value,
        customer_lastname: formState.inputs.lastname.value,
        customer_email: formState.inputs.email.value,
        customer_address: formState.inputs.address.value,
        customer_city: formState.inputs.city.value,
        customer_state: formState.inputs.state.value,
        customer_country: formState.inputs.country.value,
        customer_comments: formState.inputs.comments.value,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
      },
    };

    fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT}/update-customer-info/customer`,
      options
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(`Customer Update ======> ${JSON.stringify(data.body)}`);
        setIsEdit(false);
      })
      .catch((error) => {
        console.log(`Error in updating customer =======> ${error}`);
        setIsEdit(false);
      });
  };

  const makeOutboundCallHandler = (event) => {
    event.preventDefault();
    console.log(`Making Outbound Call!!`);
    props.makeOutboundCall(
      outboundFormState.inputs.country.value,
      outboundFormState.inputs.phonenumber.value,
      outboundFormState.inputs.queue.value
    );
    outboundSelectHandler("queue", "", false);
    outboundSelectHandler("country", "+52", true);
    outboundInputHandler("phonenumber", "", false);
  };

  return (
    <div className={`${styles.dashboard__Container}`}>
      <div className={`${styles.dashboard__IncomingDetails}`}>
        {props.contactAttributes && props?.auth === true && (
          <div className={`${styles.dashboard__IncomingDetails__Data}`}>
            <div
              className={`${styles.dashboard__IncomingDetails__Data__Header}`}
            >
              <div
                className={`${styles.dashboard__IncomingDetails__Data__Segment}`}
              >
                <h3>On Contact....</h3>
              </div>
              <div
                className={`${styles.dashboard__IncomingDetails__Data__Segment}`}
              >
                <img src="/images/phone-call-icon.svg" />
                <h2>
                  {props.contactAttributes?.customer_phonenumber
                    ? props.contactAttributes?.customer_phonenumber
                    : " -- "}
                </h2>
              </div>
              <div
                className={`${styles.dashboard__IncomingDetails__Data__Segment}`}
              >
                <img src="/images/random-icon.svg" />
                <h2>{props.queue}</h2>
              </div>
            </div>
            <Divider variant="middle" />
            <div className={`${styles.dashboard__IncomingDetails__Data__Body}`}>
              <div
                className={`${styles.dashboard__IncomingDetails__Data__Edit}`}
              >
                {!isEdit && (
                  <div
                    className={`${styles.dashboard__IncomingDetails__Data__EditIcons}`}
                  >
                    <img
                      src="/images/pencil-icon.svg"
                      onClick={() => setIsEdit(true)}
                    />
                  </div>
                )}
                {isEdit && (
                  <div
                    className={`${styles.dashboard__IncomingDetails__Data__EditIcons}`}
                  >
                    <img
                      src="/images/check-mark-icon.svg"
                      onClick={updateCustomerData}
                    />
                    <img
                      src="/images/close-icon.svg"
                      onClick={() => setIsEdit(false)}
                    />
                  </div>
                )}
              </div>

              <div
                className={`${styles.dashboard__IncomingDetails__Data__RowSegment}`}
              >
                <div
                  className={`${styles.dashboard__IncomingDetails__Data__RowSegment__Data}`}
                >
                  {!isEdit && <h3>{formState.inputs.firstname.value}</h3>}

                  {isEdit && (
                    <Input
                      id="firstname"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder={formState.inputs.firstname.value}
                      validators={[]}
                      onInput={inputHandler}
                      isDisabled={false}
                      value={formState.inputs.firstname.value}
                      valid={formState.inputs.firstname.isValid}
                    />
                  )}

                  <h5>First Name</h5>
                </div>

                <div
                  className={`${styles.dashboard__IncomingDetails__Data__RowSegment__Data}`}
                >
                  {!isEdit && <h3>{formState.inputs.lastname.value}</h3>}

                  {isEdit && (
                    <Input
                      id="lastname"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder={formState.inputs.lastname.value}
                      validators={[]}
                      onInput={inputHandler}
                      isDisabled={false}
                      value={formState.inputs.lastname.value}
                      valid={formState.inputs.lastname.isValid}
                    />
                  )}

                  <h5>Last Name</h5>
                </div>
              </div>

              <div
                className={`${styles.dashboard__IncomingDetails__Data__RowSegment}`}
              >
                <div
                  className={`${styles.dashboard__IncomingDetails__Data__RowSegment__Data}`}
                >
                  {!isEdit && <h3>{formState.inputs.email.value}</h3>}

                  {isEdit && (
                    <Input
                      id="email"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder={formState.inputs.email.value}
                      validators={[]}
                      onInput={inputHandler}
                      isDisabled={false}
                      value={formState.inputs.email.value}
                      valid={formState.inputs.email.isValid}
                    />
                  )}

                  <h5>Email</h5>
                </div>

                <div
                  className={`${styles.dashboard__IncomingDetails__Data__RowSegment__Data}`}
                >
                  {!isEdit && <h3>{formState.inputs.comments.value}</h3>}

                  {isEdit && (
                    <Input
                      id="comments"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder={formState.inputs.comments.value}
                      validators={[]}
                      onInput={inputHandler}
                      isDisabled={false}
                      value={formState.inputs.comments.value}
                      valid={formState.inputs.comments.isValid}
                    />
                  )}

                  <h5>Comments</h5>
                </div>
              </div>

              <div
                className={`${styles.dashboard__IncomingDetails__Data__RowSegment}`}
              >
                <div
                  className={`${styles.dashboard__IncomingDetails__Data__RowSegment__Data}`}
                >
                  {!isEdit && <h3>{formState.inputs.address.value}</h3>}

                  {isEdit && (
                    <Input
                      id="address"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder={formState.inputs.address.value}
                      validators={[]}
                      onInput={inputHandler}
                      isDisabled={false}
                      value={formState.inputs.address.value}
                      valid={formState.inputs.address.isValid}
                    />
                  )}

                  <h5>Address</h5>
                </div>

                <div
                  className={`${styles.dashboard__IncomingDetails__Data__RowSegment__Data}`}
                >
                  {!isEdit && <h3>{formState.inputs.city.value}</h3>}

                  {isEdit && (
                    <Input
                      id="city"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder={formState.inputs.city.value}
                      validators={[]}
                      onInput={inputHandler}
                      isDisabled={false}
                      value={formState.inputs.city.value}
                      valid={formState.inputs.city.isValid}
                    />
                  )}

                  <h5>City</h5>
                </div>
              </div>

              <div
                className={`${styles.dashboard__IncomingDetails__Data__RowSegment}`}
              >
                <div
                  className={`${styles.dashboard__IncomingDetails__Data__RowSegment__Data}`}
                >
                  {!isEdit && <h3>{formState.inputs.state.value}</h3>}

                  {isEdit && (
                    <Input
                      id="state"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder={formState.inputs.state.value}
                      validators={[]}
                      onInput={inputHandler}
                      isDisabled={false}
                      value={formState.inputs.state.value}
                      valid={formState.inputs.state.isValid}
                    />
                  )}

                  <h5>State</h5>
                </div>

                <div
                  className={`${styles.dashboard__IncomingDetails__Data__RowSegment__Data}`}
                >
                  {!isEdit && <h3>{formState.inputs.country.value}</h3>}

                  {isEdit && (
                    <Input
                      id="country"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder={formState.inputs.country.value}
                      validators={[]}
                      onInput={inputHandler}
                      isDisabled={false}
                      value={formState.inputs.country.value}
                      valid={formState.inputs.country.isValid}
                    />
                  )}

                  <h5>Country</h5>
                </div>
              </div>
            </div>
          </div>
        )}
        {!props.contactAttributes &&
          queueOptions &&
          countryOptions &&
          props?.auth === true && (
            <div className={`${styles.dashboard__IncomingDetails__NoData}`}>
              <div
                className={`${styles.dashboard__IncomingDetails__NoData__Header}`}
              >
                <form
                  className={`${styles.dashboard__IncomingDetails__NoData__Outbound}`}
                  onSubmit={makeOutboundCallHandler}
                >
                  <div
                    className={`${styles.dashboard__IncomingDetails__NoData__Outbound__Segment}`}
                  >
                    <div
                      className={`${styles.dashboard__IncomingDetails__NoData__Outbound__Queue}`}
                    >
                      <Select
                        id="queue"
                        label="Outbound Queue"
                        options={queueOptions}
                        onSelect={outboundSelectHandler}
                        value={outboundFormState.inputs.queue.value}
                        valid={outboundFormState.inputs.queue.isValid}
                      />
                    </div>

                    <div
                      className={`${styles.dashboard__IncomingDetails__NoData__Outbound__Phone}`}
                    >
                      <Select
                        id="country"
                        label="Country Code"
                        options={countryOptions}
                        onSelect={outboundSelectHandler}
                        value={outboundFormState.inputs.country.value}
                        valid={outboundFormState.inputs.country.isValid}
                      />
                      <Input
                        id="phonenumber"
                        label="Phone Number"
                        element="input"
                        type="text"
                        validators={[
                          VALIDATOR_NUMBERS,
                          VALIDATOR_MINLENGTH(10),
                          VALIDATOR_MAXLENGTH(10),
                        ]}
                        onInput={outboundInputHandler}
                        value={outboundFormState.inputs.phonenumber.value}
                        valid={outboundFormState.inputs.phonenumber.isValid}
                      />
                    </div>
                  </div>
                  <div
                    className={`${styles.dashboard__IncomingDetails__NoData__Outbound__Segment}`}
                  >
                    <Button
                      type="submit"
                      disabled={
                        !outboundFormState.isValid ||
                        !outboundFormState.inputs.phonenumber.isValid
                      }
                    >
                      CALL
                    </Button>
                  </div>
                </form>
              </div>
              <Divider variant="middle" />
              <div
                className={`${styles.dashboard__IncomingDetails__NoData__Body}`}
              >
                <h2>No Data</h2>
                <h3>Waiting for incoming call.....</h3>
              </div>
            </div>
          )}
        {props?.auth === false && (
          <div className={`${styles.dashboard__IncomingDetails__NoAuth}`}>
            <h2>Not Authenticated!</h2>
          </div>
        )}
      </div>
      <div className={`${styles.dashboard__CustomerJourney}`}>
        <div className={`${styles.dashboard__CustomerJourney__Header}`}>
          <h2>Customer Journey</h2>
        </div>
        <div className={`${styles.dashboard__CustomerJourney__Data}`}>
          {customerJourney &&
            customerJourney.length > 0 &&
            !isLoading &&
            customerJourney.map((customerJourney) => (
              <CustomerJourney
                customerJourney={customerJourney}
                key={`${customerJourney.ContactId}`}
              />
            ))}
          {customerJourney && customerJourney.length === 0 && !isLoading && (
            <div className={`${styles.dashboard__CustomerJourney__NoData}`}>
              <h2>No Data</h2>
            </div>
          )}
          {isLoading && (
            <div className={`${styles.dashboard__CustomerJourney__Loading}`}>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
