import React from "react";

import styles from "./TranscriptionItem.module.css";

const TranscriptionItem = (props) => {
  console.log(`[Transcription] =====> ${JSON.stringify(props.transcript)}`);
  return (
    <div
      className={`${styles.transcriptionitem__Container}`}
      style={
        props.transcript?.Author?.toLowerCase() === "system"
          ? {alignSelf: "flex-start"}
          : {alignSelf: "flex-end", color: "#ffffff", background: "#2ba9cd"}
      }
    >
      <div>{props.transcript.Text}</div>
    </div>
  );
};

export default TranscriptionItem;
