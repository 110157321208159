import React, {useEffect, useState} from "react";
import "amazon-connect-streams";
import moment from "moment-timezone";
import {Route, Routes, NavLink, useNavigate} from "react-router-dom";

import Dashboard from "../Dashboard/Dashboard";
import Recording from "../Recording/Recording";
import Contacts from "../Contacts/Contacts";
import Voicemails from "../Voicemails/Voicemails";
import MissedCalls from "../MissedCalls/MissedCalls";
import Callbacks from "../Callbacks/Callbacks";

import styles from "./CCPPanel.module.css";

const CCPPanel = (props) => {
  const navigate = useNavigate();

  const [ccpRefreshExhausted, setCcpRefreshExhausted] = useState(false);
  const [ccpInitialized, setCcpInitialized] = useState(false);
  const [agentQueues, setAgentQueues] = useState();
  const [isPriviledged, setIsPriviledged] = useState(false);
  const [queue, setQueue] = useState();
  const [contactAttributes, setContactAttributes] = useState();
  const [auth, setAuth] = useState(false);
  const [outbound, setOutbound] = useState({
    status: false,
    country: undefined,
    phonenumber: undefined,
    queue: undefined,
  });

  const [directOutbound, setDirectOutbound] = useState({
    status: false,
    phonenumber: undefined,
    queue: undefined,
  });

  console.log(
    `[CCP - Current CCP State: ${moment
      .tz(new Date(), process.env.REACT_APP_TIMEZONE)
      .format(
        "YYYY-MM-DD HH:mm:ss"
      )}] =====> [CCP Initialized: ${ccpInitialized},  CCP Refresh Exhausted: ${ccpRefreshExhausted}]`
  );

  const makeOutboundCallHandler = (country, phonenumber, queue) => {
    setOutbound({
      status: true,
      country,
      phonenumber,
      queue,
    });
  };

  const makeDirectOutboundCallHandler = (phonenumber, queue) => {
    console.log(`Making Direct Outbound Call ======>${phonenumber}, ${queue}`);
    setDirectOutbound({
      status: true,
      phonenumber,
      queue,
    });
  };

  useEffect(() => {
    console.log(
      `[CCP: ${moment
        .tz(new Date(), process.env.REACT_APP_TIMEZONE)
        .format("YYYY-MM-DD HH:mm:ss")}] =====> Loading CCP`
    );

    // eslint-disable-next-line no-undef
    connect.agentApp.initApp(
      "ccp",
      "ccp_panel",
      `${process.env.REACT_APP_CCP_URL}/ccp-v2/`,
      {
        ccpParams: {
          loginPopup: true,
          loginPopupAutoClose: true,
          ccpAckTimeout: 2000,
          ccpSynTimeout: 3000,
          ccpLoadTimeout: 10000,
        },
        style: "width:400px; height:600px;",
      }
    );

    // eslint-disable-next-line no-undef
    connect.core.onInitialized(() => {
      console.log(
        `[CCP - On Initialized: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format("YYYY-MM-DD HH:mm:ss")}]=====> Connect Initialized`
      );
      setCcpRefreshExhausted(false);
      setCcpInitialized(true);
      setAuth(true);
      navigate("/");
    });

    // eslint-disable-next-line no-undef
    connect.core.onAuthorizeSuccess(() => {
      console.log(
        `[CCP - On Authorize Success: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format("YYYY-MM-DD HH:mm:ss")}] =====> Connect Authorized`
      );
      setAuth(true);
    });

    // eslint-disable-next-line no-undef
    connect.core.onAuthorizeRetriesExhausted(() => {
      console.log(
        `[CCP - OnAuthorizeRetriesExhausted: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format(
            "YYYY-MM-DD HH:mm:ss"
          )}] =====> Connect Authorization Exhaused`
      );
      setAuth(false);
    });

    // eslint-disable-next-line no-undef
    connect.core.onIframeRetriesExhausted(() => {
      console.log(
        `[CCP - OnIFrameRetriesExhausted: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format("YYYY-MM-DD HH:mm:ss")}] =====> Connect CCP Reload Exhausted`
      );
      setCcpRefreshExhausted(true);
      setCcpInitialized(false);
      setAuth(false);
    });

    // eslint-disable-next-line no-undef
    connect.core.onAuthFail(() => {
      console.log(
        `[CCP - OnAuthFail: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format("YYYY-MM-DD HH:mm:ss")}] =====> Connect Authorization Failed`
      );
      setAuth(false);
    });

    // eslint-disable-next-line no-undef
    connect.core.onAccessDenied(() => {
      console.log(
        `[CCP - OnAccessDenied: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format("YYYY-MM-DD HH:mm:ss")}] =====> Connect Access Denied`
      );
      setAuth(false);
    });

    // eslint-disable-next-line no-undef
    connect.core.onCTIAuthorizeRetriesExhausted(() => {
      console.log(
        `[CCP - onCTIAuthorizeRetriesExhausted: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format(
            "YYYY-MM-DD HH:mm:ss"
          )}] =====> Connect CTI Retries Exhausted!`
      );
      setAuth(false);
    });

    const processOutboundCall = () => {
      agent.connect(endpoint, {
        queueARN,
        success: function () {
          console.log(
            `[CCP - Outbound Call: ${moment
              .tz(new Date(), process.env.REACT_APP_TIMEZONE)
              .format("YYYY-MM-DD HH:mm:ss")}] =====> Outbound Call Connected!`
          );
          setOutbound({
            status: false,
            country: undefined,
            phonenumber: undefined,
            queue: undefined,
          });
          setDirectOutbound({
            status: false,
            phonenumber: undefined,
            queue: undefined,
          });
        },
        failure: function (err) {
          console.error(
            `[CCP - Outbound Call: ${moment
              .tz(new Date(), process.env.REACT_APP_TIMEZONE)
              .format(
                "YYYY-MM-DD HH:mm:ss"
              )}] =====> Outbound Call Connection Failed [${err.message}]!`
          );
          setOutbound({
            status: false,
            country: undefined,
            phonenumber: undefined,
            queue: undefined,
          });
          setDirectOutbound({
            status: false,
            phonenumber: undefined,
            queue: undefined,
          });
        },
      });
    };

    if (outbound && outbound?.status === true) {
      // eslint-disable-next-line no-undef
      var endpoint = connect.Endpoint.byPhoneNumber(
        `${outbound.country}${outbound.phonenumber}`
      );

      // eslint-disable-next-line no-undef
      var agent = new connect.Agent();
      var queueARN = outbound.queue;

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_AUTH_TOKEN,
        },
      };

      fetch(
        `${
          process.env.REACT_APP_AWS_ENDPOINT
        }/get-customer-info/phone/${outbound.country.replace("+", "")}${
          outbound.phonenumber
        }`,
        options
      )
        .then((resp) => resp.json())
        .then((data) => {
          let contactAttributes = {
            customer_phonenumber: `${outbound.country}${outbound.phonenumber}`,
          };

          if (data) {
            contactAttributes = {
              ...data,
              customer_phonenumber: `${outbound.country}${outbound.phonenumber}`,
            };
          }
          console.log(
            `[CCP - On Outbound Call: ${moment
              .tz(new Date(), process.env.REACT_APP_TIMEZONE)
              .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
              contactAttributes
            )}`
          );
          setContactAttributes(contactAttributes);
          processOutboundCall();
        })
        .catch((error) => {
          console.log(`Error in getting customer =======> ${error}`);
          processOutboundCall();
        });
    }

    if (directOutbound && directOutbound?.status === true) {
      console.log(`Connecting to =======> ${directOutbound.phonenumber}`);
      // eslint-disable-next-line no-undef
      var endpoint = connect.Endpoint.byPhoneNumber(
        `${directOutbound.phonenumber}`
      );

      // eslint-disable-next-line no-undef
      var agent = new connect.Agent();
      var queueARN = directOutbound.queue;

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_AUTH_TOKEN,
        },
      };

      fetch(
        `${
          process.env.REACT_APP_AWS_ENDPOINT
        }/get-customer-info/phone/${directOutbound.phonenumber?.replace(
          "+",
          ""
        )}`,
        options
      )
        .then((resp) => resp.json())
        .then((data) => {
          let contactAttributes = {
            customer_phonenumber: `${directOutbound.phonenumber}`,
          };

          if (data) {
            contactAttributes = {
              ...data,
              customer_phonenumber: `${directOutbound.phonenumber}`,
            };
          }
          console.log(
            `[CCP - On Outbound Call: ${moment
              .tz(new Date(), process.env.REACT_APP_TIMEZONE)
              .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
              contactAttributes
            )}`
          );
          setContactAttributes(contactAttributes);
          processOutboundCall();
        })
        .catch((error) => {
          console.log(`Error in getting customer =======> ${error}`);
          processOutboundCall();
        });
    }

    // eslint-disable-next-line no-undef
    connect.contact((contact) => {
      contact.onPending((contact) => {
        console.log(
          `[CCP - On Pending: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onRefresh((contact) => {
        console.log(
          `[CCP - On Refresh: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onIncoming((contact) => {
        console.log(
          `[CCP - On Incoming: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onAccepted((contact) => {
        console.log(
          `[CCP - On Accepted: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onConnecting((contact) => {
        console.log(
          `[CCP - On Connecting: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
        console.log(
          `[CCP - On Connecting: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
            contact.getQueue()
          )}`
        );
        let attributes = contact.getAttributes();

        if (attributes && attributes?.direction?.value === "inbound") {
          let newAttributes = {};
          for (let key in attributes) {
            newAttributes[attributes[key].name] = attributes[key].value;
          }
          console.log(
            `[CCP - On Connecting [Contact Attributes]: ${moment
              .tz(new Date(), process.env.REACT_APP_TIMEZONE)
              .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
              newAttributes
            )}`
          );
          setContactAttributes(newAttributes);
        }

        setQueue(contact.getQueue().name);
        navigate("/");
      });
      contact.onConnected((contact) => {
        console.log(
          `[CCP - On Connected: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onEnded((contact) => {
        // console.log(
        //   `[CCP - On Ended: ${moment
        //     .tz(new Date(), process.env.REACT_APP_TIMEZONE)
        //     .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        // );
        setQueue();
        setContactAttributes();
      });
      contact.onMissed((contact) => {
        console.log(
          `[CCP - On Missed: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onDestroy((contact) => {
        // console.log(
        //   `[CCP - On Destroyed: ${moment
        //     .tz(new Date(), process.env.REACT_APP_TIMEZONE)
        //     .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        // );
      });
      contact.onACW((contact) => {
        console.log(
          `[CCP - On After Call Work: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onError((contact) => {
        console.log(
          `[CCP - On Error: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
    });

    // eslint-disable-next-line no-undef
    connect.agent((agent) => {
      let agentRoutingProfile = agent.getRoutingProfile();

      let queues = agentRoutingProfile.queues.map((x) => {
        if (x.name && x.name !== null) {
          return {
            name: x.name,
            value: x.queueARN,
          };
        }
      });

      setAgentQueues(queues);

      agent.onRefresh((agent) => {
        // console.log(
        //   `[CCP Agent On Refresh: ${moment
        //     .tz(new Date(), process.env.REACT_APP_TIMEZONE)
        //     .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
        //     agent.getName()
        //   )}`
        // );
        console.log(
          `[CCP Agent On Refresh: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
            agent.getState()
          )}`
        );
        // console.log(
        //   `[CCP Agent On Refresh: ${moment
        //     .tz(new Date(), process.env.REACT_APP_TIMEZONE)
        //     .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
        //     agent.getConfiguration()
        //   )}`
        // );
        if (
          agent
            .getConfiguration()
            .permissions.includes("ccpRealtimeContactLens")
        ) {
          setIsPriviledged(true);
        }

        props.agentInfo({
          agentState: {
            ...agent.getState(),
          },
          agentName: agent.getName(),
        });

        // console.log(
        //   `[CCP Agent On Refresh: ${moment
        //     .tz(new Date(), process.env.REACT_APP_TIMEZONE)
        //     .format(
        //       "YYYY-MM-DD HH:mm:ss"
        //     )}] =====> Agent State: ${JSON.stringify(agent.getState())}}`
        // );

        if (agent.getState().name === "MissedCallAgent") {
          let offlineState = agent
            .getAgentStates()
            .filter((state) => state.name === "Offline")[0];

          setTimeout(() => {
            console.log(
              `[CCP Agent On Refresh: ${moment
                .tz(new Date(), process.env.REACT_APP_TIMEZONE)
                .format("YYYY-MM-DD HH:mm:ss")}] =====> Function executed!}`
            );

            agent.setState(
              offlineState,
              {
                success: function () {
                  console.log(
                    `[CCP Agent On Refresh: ${moment
                      .tz(new Date(), process.env.REACT_APP_TIMEZONE)
                      .format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}] =====> State Changed Successfully!}`
                  );
                },
                failure: function (err) {
                  console.log(
                    `[CCP Agent On Refresh: ${moment
                      .tz(new Date(), process.env.REACT_APP_TIMEZONE)
                      .format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}] =====> Error in State Change ${err.message}!}`
                  );
                },
              },
              {enqueueNextState: false}
            );
          }, 5000);
        }
      });
      agent.onStateChange((agentStateChange) => {
        // console.log(
        //   `[CCP - Agent On State Changed: ${moment
        //     .tz(new Date(), process.env.REACT_APP_TIMEZONE)
        //     .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
        //     agentStateChange
        //   )}`
        // );
      });
      agent.onRoutable((agent) => {
        //console.log(`[CCP - Agent On Routable: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onNotRoutable((agent) => {
        //console.log(`[CCP - Agent On Not Routable: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onOffline((agent) => {
        //console.log(`[CCP - Agent On Offline: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onError((agent) => {
        //console.log(`[CCP - Agent On Error: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onSoftphoneError((error) => {
        //console.log(`[CCP - Agent On Softphone Error: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(error)}`)
      });
      agent.onWebSocketConnectionLost((agent) => {
        //console.log(`[CCP - Agent On Connection Lost: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onWebSocketConnectionGained((agent) => {
        //console.log(`[CCP - Agent On WebSocket Connection Gained: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onAfterCallWork((agent) => {
        //console.log(`[CCP - Agent On ACW: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onMuteToggle((obj) => {
        //console.log(`[CCP - Agent On Mute Toggle: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(obj)}`)
      });
      agent.onSpeakerDeviceChanged((obj) => {
        //console.log(`[CCP - Agent On Speaker Device Changed: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(obj)}`)
      });
      agent.onMicrophoneDeviceChanged((obj) => {
        //console.log(`[CCP - Agent On Microphone Device Changed: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(obj)}`)
      });
      agent.onRingerDeviceChanged((obj) => {
        //console.log(`[CCP - Agent On Ringer Changed: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(obj)}`)
      });
    });
  }, [outbound, directOutbound]);

  return (
    <div className={`${styles.ccppanel_Container}`}>
      <div
        id="ccp_panel"
        className={`${styles.ccppanel_Panel}`}
        style={{display: ccpInitialized ? "block" : "none"}}
      ></div>
      {!ccpInitialized && !ccpRefreshExhausted && (
        <div className={`${styles.ccppanel_Panel}`}>
          <div className={`${styles.ccppanel_Panel__Loading}`}>
            <img src="./images/three-dots.svg" alt="Loading..." />
            <h2>Please login!</h2>
            <p>
              Kindly refresh your browser, if CCP is not shown even after login!
            </p>
          </div>
        </div>
      )}

      {!ccpInitialized && ccpRefreshExhausted && (
        <div className={`${styles.ccppanel_Panel}`}>
          <div className={`${styles.ccppanel_Panel__Loading}`}>
            <h2>Kindly refresh your browser!</h2>
            <p>Max re-tries to initialize CCP is exhausted.</p>
          </div>
        </div>
      )}
      <div className={`${styles.ccppanel_Navigation}`}>
        <div className={`${styles.ccppanel__tabs_container}`}>
          <div className={`${styles.ccppanel__tabs}`}>
            <NavLink
              to={`/`}
              className={({isActive}) =>
                isActive
                  ? `${styles.ccppanel__tabs__active}`
                  : `${styles.ccppanel__tabs__inactive}`
              }
            >
              <div className={`${styles.ccppanel__tabs__header}`}>
                <h2>Dashboard</h2>
              </div>
            </NavLink>
          </div>
          <div className={`${styles.ccppanel__tabs}`}>
            <NavLink
              to={`/recordings`}
              className={({isActive}) =>
                isActive
                  ? `${styles.ccppanel__tabs__active}`
                  : `${styles.ccppanel__tabs__inactive}`
              }
            >
              <div className={`${styles.ccppanel__tabs__header}`}>
                <h2>Recordings</h2>
              </div>
            </NavLink>
          </div>
          <div className={`${styles.ccppanel__tabs}`}>
            <NavLink
              to={`/contacts`}
              className={({isActive}) =>
                isActive
                  ? `${styles.ccppanel__tabs__active}`
                  : `${styles.ccppanel__tabs__inactive}`
              }
            >
              <div className={`${styles.ccppanel__tabs__header}`}>
                <h2>Contacts</h2>
              </div>
            </NavLink>
          </div>
          <div className={`${styles.ccppanel__tabs}`}>
            <NavLink
              to={`/voicemails`}
              className={({isActive}) =>
                isActive
                  ? `${styles.ccppanel__tabs__active}`
                  : `${styles.ccppanel__tabs__inactive}`
              }
            >
              <div className={`${styles.ccppanel__tabs__header}`}>
                <h2>Voicemails</h2>
              </div>
            </NavLink>
          </div>
          <div className={`${styles.ccppanel__tabs}`}>
            <NavLink
              to={`/missedcalls`}
              className={({isActive}) =>
                isActive
                  ? `${styles.ccppanel__tabs__active}`
                  : `${styles.ccppanel__tabs__inactive}`
              }
            >
              <div className={`${styles.ccppanel__tabs__header}`}>
                <h2>Missed</h2>
              </div>
            </NavLink>
          </div>

          <div className={`${styles.ccppanel__tabs}`}>
            <NavLink
              to={`/callbacks`}
              className={({isActive}) =>
                isActive
                  ? `${styles.ccppanel__tabs__active}`
                  : `${styles.ccppanel__tabs__inactive}`
              }
            >
              <div className={`${styles.ccppanel__tabs__header}`}>
                <h2>Callbacks</h2>
              </div>
            </NavLink>
          </div>
        </div>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <Dashboard
                queue={queue}
                contactAttributes={contactAttributes}
                agentQueues={agentQueues}
                makeOutboundCall={makeOutboundCallHandler}
                auth={auth}
              />
            }
          />
          <Route
            path="/recordings"
            element={
              <Recording
                isPriviledged={isPriviledged}
                agentQueues={agentQueues}
                auth={auth}
              />
            }
          />
          <Route
            path="/contacts"
            element={
              <Contacts
                isPriviledged={isPriviledged}
                agentQueues={agentQueues}
                makeDirectOutboundCall={makeDirectOutboundCallHandler}
                auth={auth}
              />
            }
          />
          <Route
            path="/voicemails"
            element={
              <Voicemails
                isPriviledged={isPriviledged}
                agentQueues={agentQueues}
                auth={auth}
              />
            }
          />
          <Route
            path="/missedcalls"
            element={
              <MissedCalls
                isPriviledged={isPriviledged}
                agentQueues={agentQueues}
                makeDirectOutboundCall={makeDirectOutboundCallHandler}
                auth={auth}
              />
            }
          />
          <Route
            path="/callbacks"
            element={
              <Callbacks
                isPriviledged={isPriviledged}
                agentQueues={agentQueues}
                makeDirectOutboundCall={makeDirectOutboundCallHandler}
                auth={auth}
              />
            }
          />
          <Route
            path="*"
            element={
              <Dashboard
                queue={queue}
                contactAttributes={contactAttributes}
                agentQueues={agentQueues}
                makeOutboundCall={makeOutboundCallHandler}
                auth={auth}
              />
            }
            replace
          />
        </Routes>
      </div>
    </div>
  );
};

export default React.memo(CCPPanel);
