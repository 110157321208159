import {useEffect, useState} from "react";

import Loading from "../UI/Loading";

import styles from "./Voicemails.module.css";
import VoicemailItem from "./VoicemailItem";

const Voicemails = (props) => {
  const [queues, setQueues] = useState();
  const [voicemails, setVoicemails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let queues = props.agentQueues?.map((x) => {
      if (x && x?.name != "null") {
        return x.name.toLowerCase();
      }
    });

    setQueues(queues);

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (queues && queues?.length > 0) {
      setIsLoading(true);
      let voicemailsArr = [];
      let promises = [];
      queues.forEach((queue) => {
        if (queue) {
          let promise = new Promise((resolve) => {
            fetch(
              `${process.env.REACT_APP_AWS_ENDPOINT}/voicemails/client_name/${queue}`,
              options
            )
              .then((resp) => resp.json())
              .then((data) => {
                if (data.status_code === 200) {
                  voicemailsArr = [...voicemailsArr, ...data.body];
                  resolve(data.body);
                }
              })
              .catch((error) => {
                console.log(`Error in getting voicemails =======> ${error}`);
              });
          });
          promises.push(promise);
        }
      });

      Promise.all(promises).then((elements) => {
        voicemailsArr.sort(function (a, b) {
          return new Date(b.start_time) - new Date(a.start_time);
        });
        setVoicemails(voicemailsArr);
        setIsLoading(false);
      });
    }
  }, []);

  console.log(
    `setVoicemails: is Priviledged ==========> ${JSON.stringify(
      props.isPriviledged
    )}`
  );

  return (
    <div className={`${styles.voicemails__Container}`}>
      {voicemails &&
        voicemails.length > 0 &&
        !isLoading &&
        props?.auth === true &&
        voicemails.map((voicemail) => (
          <VoicemailItem
            voicemail={voicemail}
            isPriviledged={props.isPriviledged}
            key={`${voicemail.recordingUrl}`}
          />
        ))}
      {voicemails &&
        voicemails.length === 0 &&
        props?.auth === true &&
        !isLoading && (
          <div className={`${styles.voicemail__NoData}`}>
            <h2>No Voicemails</h2>
          </div>
        )}
      {props?.auth === false && (
        <div className={`${styles.voicemail__NoData}`}>
          <h2>Not Authenticated!</h2>
        </div>
      )}
      {isLoading && (
        <div className={`${styles.voicemail__Loading}`}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Voicemails;
