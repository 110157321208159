import {useEffect, useState} from "react";
import Divider from "@mui/material/Divider";

import Modal from "../UI/Modal";
import Input from "../UI/Input";
import Select from "../UI/Select";
import Button from "../UI/Button";
import ContactItem from "./ContactItem";
import Loading from "../UI/Loading";

import styles from "./Contacts.module.css";

import {useForm} from "../Hooks/form-hook";

import {
  VALIDATOR_NUMBERS,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
} from "../UI/util/validators";

const Contacts = (props) => {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState();
  const [selectedContact, setSelectedContact] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [countryOptions, setCountryOptions] = useState([
    {
      value: "+52",
      name: "+52 (Mexico)",
    },
    {
      value: "+1",
      name: "+1 (US & Canada)",
    },
  ]);

  const [queueOptions, setQueueOptions] = useState([
    {
      value: "",
      name: "--Please Select--",
    },
  ]);

  useEffect(() => {
    let queues = props.agentQueues?.filter((x) => {
      return x && x !== null;
    });
    if (queues && queues.length > 0) {
      setQueueOptions([
        {
          value: "",
          name: "--Please Select--",
        },
        ...queues,
      ]);
    }
  }, [props.agentQueues]);

  const [outboundFormState, outboundInputHandler, outboundSelectHandler] =
    useForm(
      {
        queue: {
          value: "",
          isValid: false,
        },
      },
      false
    );

  const [formState, inputHandler, selectHandler] = useForm(
    {
      firstname: {
        value: "",
        isValid: true,
      },
      lastname: {
        value: "",
        isValid: true,
      },
      email: {
        value: "",
        isValid: true,
      },
      address: {
        value: "",
        isValid: true,
      },
      city: {
        value: "",
        isValid: true,
      },
      state: {
        value: "",
        isValid: true,
      },
      country: {
        value: "",
        isValid: true,
      },
      comments: {
        value: "",
        isValid: true,
      },
    },
    true
  );

  const [addFormState, addInputHandler, addSelectHandler] = useForm(
    {
      countrycode: {
        value: "+52",
        isValid: true,
      },
      phonenumber: {
        value: "",
        isValid: false,
      },
      firstname: {
        value: "",
        isValid: false,
      },
      lastname: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: true,
      },
      address: {
        value: "",
        isValid: true,
      },
      city: {
        value: "",
        isValid: true,
      },
      state: {
        value: "",
        isValid: true,
      },
      country: {
        value: "",
        isValid: true,
      },
      comments: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  const [searchFormState, searchInputHandler, searchSelectHandler] = useForm(
    {
      sort: {
        value: "",
        isValid: true,
      },
      search: {
        value: "",
        isValid: true,
      },
    },
    true
  );

  const onDetailsSelectHandler = (contact) => {
    setSelectedContact(contact);
  };

  const updateCustomerData = () => {
    console.log(
      `Customer Phone Number ======> ${selectedContact.customer_phonenumber?.replace(
        "+",
        ""
      )}`
    );
    console.log(
      `Customer Firstname ======> ${formState.inputs.firstname.value}`
    );
    console.log(`Customer Lastname ======> ${formState.inputs.lastname.value}`);
    console.log(`Customer Email ======> ${formState.inputs.email.value}`);
    console.log(`Customer Address ======> ${formState.inputs.address.value}`);
    console.log(`Customer City ======> ${formState.inputs.city.value}`);
    console.log(`Customer State ======> ${formState.inputs.state.value}`);
    console.log(`Customer Country ======> ${formState.inputs.country.value}`);
    console.log(`Customer Comments ======> ${formState.inputs.comments.value}`);

    const options = {
      method: "PATCH",
      body: JSON.stringify({
        customer_phonenumber: selectedContact.customer_phonenumber?.replace(
          "+",
          ""
        ),
        customer_firstname: formState.inputs.firstname.value,
        customer_lastname: formState.inputs.lastname.value,
        customer_email: formState.inputs.email.value,
        customer_address: formState.inputs.address.value,
        customer_city: formState.inputs.city.value,
        customer_state: formState.inputs.state.value,
        customer_country: formState.inputs.country.value,
        customer_comments: formState.inputs.comments.value,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
      },
    };

    fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT}/update-customer-info/customer`,
      options
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(`Customer Update ======> ${JSON.stringify(data.body)}`);
        setIsEdit(false);
      })
      .catch((error) => {
        console.log(`Error in updating customer =======> ${error}`);
        setIsEdit(false);
      });
  };

  const makeDirectOutboundCallHandler = (event) => {
    event.preventDefault();
    console.log(`Making Direct Outbound Call!! =========>`);
    props.makeDirectOutboundCall(
      `+${selectedContact.customer_phonenumber}`,
      outboundFormState.inputs.queue.value
    );
    outboundSelectHandler("queue", "", false);
  };

  useEffect(() => {
    if (isEdit === false && isAdd === false && deleteConfirmation === false) {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_AUTH_TOKEN,
        },
      };

      setIsLoading(true);
      fetch(`${process.env.REACT_APP_AWS_ENDPOINT}/get-customer-info`, options)
        .then((resp) => resp.json())
        .then((data) => {
          console.log(`Customer Details =======> ${JSON.stringify(data)}`);

          let exists = data.find(
            (x) =>
              x.customer_phonenumber === selectedContact?.customer_phonenumber
          );
          exists && exists.length > 0
            ? setSelectedContact(selectedContact)
            : setSelectedContact(data[0]);

          setContacts(data);
          setFilteredContacts(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(`Error in getting recordings =======> ${error}`);
          setIsLoading(false);
        });
    }
  }, [isEdit, isAdd, deleteConfirmation]);

  useEffect(() => {
    inputHandler(
      "firstname",
      selectedContact?.customer_firstname
        ? selectedContact?.customer_firstname
        : "--",
      true
    );
    inputHandler(
      "lastname",
      selectedContact?.customer_lastname
        ? selectedContact?.customer_lastname
        : "--",
      true
    );
    inputHandler(
      "email",
      selectedContact?.customer_email ? selectedContact?.customer_email : "--",
      true
    );
    inputHandler(
      "address",
      selectedContact?.customer_address
        ? selectedContact?.customer_address
        : "--",
      true
    );
    inputHandler(
      "city",
      selectedContact?.customer_city ? selectedContact?.customer_city : "--",
      true
    );
    inputHandler(
      "state",
      selectedContact?.customer_state ? selectedContact?.customer_state : "--",
      true
    );
    inputHandler(
      "country",
      selectedContact?.customer_country
        ? selectedContact?.customer_country
        : "--",
      true
    );
    inputHandler(
      "comments",
      selectedContact?.customer_comments
        ? selectedContact?.customer_comments
        : "--",
      true
    );
  }, [selectedContact]);

  const sortedContacts = (sortCriteria) => {
    if (filteredContacts && filteredContacts.length > 0) {
      switch (sortCriteria) {
        case "lastNameAsc":
          filteredContacts.sort((a, b) =>
            a.customer_lastname.trim().toLowerCase() >
            b.customer_lastname.trim().toLowerCase()
              ? 1
              : b.customer_lastname.trim().toLowerCase() >
                a.customer_lastname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "lastNameDesc":
          filteredContacts.sort((a, b) =>
            a.customer_lastname.trim().toLowerCase() <
            b.customer_lastname.trim().toLowerCase()
              ? 1
              : b.customer_lastname.trim().toLowerCase() <
                a.customer_lastname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "firstNameAsc":
          filteredContacts.sort((a, b) =>
            a.customer_firstname.trim().toLowerCase() >
            b.customer_firstname.trim().toLowerCase()
              ? 1
              : b.customer_firstname.trim().toLowerCase() >
                a.customer_firstname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "lastNameDesc":
          filteredContacts.sort((a, b) =>
            a.customer_firstname.trim().toLowerCase() <
            b.customer_firstname.trim().toLowerCase()
              ? 1
              : b.customer_firstname.trim().toLowerCase() <
                a.customer_firstname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "cityAsc":
          filteredContacts.sort((a, b) =>
            a.customer_city.trim().toLowerCase() >
            b.customer_city.trim().toLowerCase()
              ? 1
              : b.customer_city.trim().toLowerCase() >
                a.customer_city.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "cityDesc":
          filteredContacts.sort((a, b) =>
            a.customer_city.trim().toLowerCase() <
            b.customer_city.trim().toLowerCase()
              ? 1
              : b.customer_city.trim().toLowerCase() <
                a.customer_city.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "stateAsc":
          filteredContacts.sort((a, b) =>
            a.customer_state.trim().toLowerCase() >
            b.customer_state.trim().toLowerCase()
              ? 1
              : b.customer_state.trim().toLowerCase() >
                a.customer_state.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "stateDesc":
          filteredContacts.sort((a, b) =>
            a.customer_state.trim().toLowerCase() <
            b.customer_state.trim().toLowerCase()
              ? 1
              : b.customer_state.trim().toLowerCase() <
                a.customer_state.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
        case "phoneAsc":
          filteredContacts.sort((a, b) => {
            return a.customer_phonenumber > b.customer_phonenumber
              ? 1
              : b.customer_phonenumber > a.customer_phonenumber
              ? -1
              : 0;
          });
          break;
        case "phoneDesc":
          filteredContacts.sort((a, b) => {
            return a.customer_phonenumber < b.customer_phonenumber
              ? 1
              : b.customer_phonenumber < a.customer_phonenumber
              ? -1
              : 0;
          });
          break;
        default:
          filteredContacts.sort((a, b) =>
            a.customer_firstname.trim().toLowerCase() >
            b.customer_firstname.trim().toLowerCase()
              ? 1
              : b.customer_firstname.trim().toLowerCase() >
                a.customer_firstname.trim().toLowerCase()
              ? -1
              : 0
          );
          break;
      }
      setFilteredContacts(filteredContacts);
    } else {
      setFilteredContacts(filteredContacts);
    }
  };

  const searchedContacts = (searchedKeyword) => {
    if (contacts && contacts.length > 0) {
      let filtered = contacts.filter((element) => {
        let partialResult = false;
        if (searchedKeyword.trim().length > 0) {
          if (
            element.customer_firstname
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_lastname
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_phonenumber
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_email
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else if (
            element.customer_comments
              ?.toLowerCase()
              ?.includes(searchedKeyword?.toLowerCase())
          ) {
            partialResult = true;
          } else {
            partialResult = false;
          }
        } else {
          partialResult = true;
        }
        if (partialResult) {
          return true;
        } else {
          return false;
        }
      });
      if (filtered.length > 0) {
        setFilteredContacts(filtered);
        if (selectedContact) {
          let find = filtered.find(
            (x) =>
              x.customer_phonenumber === selectedContact?.customer_phonenumber
          );
          if (find && find.length > 0) {
            setSelectedContact(selectedContact);
          } else {
            setSelectedContact(filtered[0]);
          }
        } else {
          setSelectedContact(filtered[0]);
        }
      } else {
        setFilteredContacts();
        setSelectedContact();
      }
    } else {
      setFilteredContacts(contacts);
    }
  };

  const addContactHandler = () => {
    console.log(
      `Adding Customer =======> [
		Phone: ${addFormState.inputs.countrycode.value}${addFormState.inputs.phonenumber.value},
		Firstname: ${addFormState.inputs.firstname.value}, 
		Lastname: ${addFormState.inputs.lastname.value},
		Email: ${addFormState.inputs.email.value}, 
		Address: ${addFormState.inputs.address.value},
		City: ${addFormState.inputs.city.value}, 
		State: ${addFormState.inputs.state.value},
		Country: ${addFormState.inputs.country.value}, 
		Comments: ${addFormState.inputs.comments.value}
		]`
    );

    const options = {
      method: "PATCH",
      body: JSON.stringify({
        customer_phonenumber: `${addFormState.inputs.countrycode.value.replace(
          "+",
          ""
        )}${addFormState.inputs.phonenumber.value}`,
        customer_firstname: addFormState.inputs.firstname.value,
        customer_lastname: addFormState.inputs.lastname.value,
        customer_email: addFormState.inputs.email.value,
        customer_address: addFormState.inputs.address.value,
        customer_city: addFormState.inputs.city.value,
        customer_state: addFormState.inputs.state.value,
        customer_country: addFormState.inputs.country.value,
        customer_comments: addFormState.inputs.comments.value,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
      },
    };

    fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT}/update-customer-info/customer`,
      options
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(`Customer Added ======> ${JSON.stringify(data.body)}`);
        setIsAdd(false);
      })
      .catch((error) => {
        console.log(`Error in adding customer =======> ${error}`);
        setIsAdd(false);
      });

    addSelectHandler("countrycode", "+52", true);
    addInputHandler("phonenumber", "", false);
    addInputHandler("firstname", "", false);
    addInputHandler("lastname", "", false);
    addInputHandler("email", "", false);
    addInputHandler("address", "", false);
    addInputHandler("city", "", false);
    addInputHandler("state", "", false);
    addInputHandler("country", "", false);
    addInputHandler("comments", "", false);
  };

  const deleteContactHandler = () => {
    console.log(`Deleting =====> ${selectedContact.customer_phonenumber}`);
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
      },
    };

    fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT}/delete-customer-info/phone/${selectedContact.customer_phonenumber}`,
      options
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(`Customer Deleted ======> ${JSON.stringify(data.body)}`);
        setDeleteConfirmation(false);
      })
      .catch((error) => {
        console.log(`Error in adding customer =======> ${error}`);
        setDeleteConfirmation(false);
      });
  };

  return (
    <>
      {props?.auth === true && (
        <div className={`${styles.contacts__Container}`}>
          <Modal
            show={isAdd === true}
            onCancel={() => setIsAdd(false)}
            className={`${styles.contacts__Add__Modal}`}
            header={
              <div className={`${styles.contacts__Add__Modal__Header}`}>
                <h2>Add Contact</h2>
                <div className={`${styles.contacts__Add__Model_RowSegment}`}>
                  <div
                    className={`${styles.contacts__Add__Model_RowSegment__Data__Phone}`}
                  >
                    <div
                      className={`${styles.contacts__Add__Model_RowSegment__Phone}`}
                    >
                      <Select
                        id="countrycode"
                        label="NA"
                        options={countryOptions}
                        onSelect={addSelectHandler}
                        value={addFormState.inputs.countrycode.value}
                        valid={addFormState.inputs.countrycode.isValid}
                      />
                      <Input
                        id="phonenumber"
                        label="NA"
                        element="input"
                        placeholder="10 digit phone number"
                        type="text"
                        validators={[
                          VALIDATOR_NUMBERS,
                          VALIDATOR_MINLENGTH(10),
                          VALIDATOR_MAXLENGTH(10),
                        ]}
                        onInput={addInputHandler}
                        value={addFormState.inputs.phonenumber.value}
                        valid={addFormState.inputs.phonenumber.isValid}
                      />
                    </div>
                    <h5>Phone Number</h5>
                  </div>
                </div>
                <div className={`${styles.contacts__Add__Model_RowSegment}`}>
                  <div
                    className={`${styles.contacts__Add__Model_RowSegment__Data}`}
                  >
                    <Input
                      id="firstname"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder="Firstname"
                      validators={[]}
                      onInput={addInputHandler}
                      isDisabled={false}
                      value={addFormState.inputs.firstname.value}
                      valid={addFormState.inputs.firstname.isValid}
                    />

                    <h5>First Name</h5>
                  </div>

                  <div
                    className={`${styles.contacts__Add__Model_RowSegment__Data}`}
                  >
                    <Input
                      id="lastname"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder="Lastname"
                      validators={[]}
                      onInput={addInputHandler}
                      isDisabled={false}
                      value={addFormState.inputs.lastname.value}
                      valid={addFormState.inputs.lastname.isValid}
                    />

                    <h5>Last Name</h5>
                  </div>
                </div>
                <div className={`${styles.contacts__Add__Model_RowSegment}`}>
                  <div
                    className={`${styles.contacts__Add__Model_RowSegment__Data}`}
                  >
                    <Input
                      id="email"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder="Email"
                      validators={[VALIDATOR_EMAIL, VALIDATOR_REQUIRE]}
                      onInput={addInputHandler}
                      isDisabled={false}
                      value={addFormState.inputs.email.value}
                      valid={addFormState.inputs.email.isValid}
                    />

                    <h5>Email</h5>
                  </div>

                  <div
                    className={`${styles.contacts__Add__Model_RowSegment__Data}`}
                  >
                    <Input
                      id="comments"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder="Comments (Optional)"
                      validators={[]}
                      onInput={addInputHandler}
                      isDisabled={false}
                      value={addFormState.inputs.comments.value}
                      valid={addFormState.inputs.comments.isValid}
                    />

                    <h5>Comments</h5>
                  </div>
                </div>
                <div className={`${styles.contacts__Add__Model_RowSegment}`}>
                  <div
                    className={`${styles.contacts__Add__Model_RowSegment__Data}`}
                  >
                    <Input
                      id="address"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder="Address"
                      validators={[]}
                      onInput={addInputHandler}
                      isDisabled={false}
                      value={addFormState.inputs.address.value}
                      valid={addFormState.inputs.address.isValid}
                    />

                    <h5>Address</h5>
                  </div>

                  <div
                    className={`${styles.contacts__Add__Model_RowSegment__Data}`}
                  >
                    <Input
                      id="city"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder="City"
                      validators={[]}
                      onInput={addInputHandler}
                      isDisabled={false}
                      value={addFormState.inputs.city.value}
                      valid={addFormState.inputs.city.isValid}
                    />

                    <h5>City</h5>
                  </div>
                </div>
                <div className={`${styles.contacts__Add__Model_RowSegment}`}>
                  <div
                    className={`${styles.contacts__Add__Model_RowSegment__Data}`}
                  >
                    <Input
                      id="state"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder="State"
                      validators={[]}
                      onInput={addInputHandler}
                      isDisabled={false}
                      value={addFormState.inputs.state.value}
                      valid={addFormState.inputs.state.isValid}
                    />

                    <h5>State</h5>
                  </div>

                  <div
                    className={`${styles.contacts__Add__Model_RowSegment__Data}`}
                  >
                    <Input
                      id="country"
                      label="NA"
                      element="input"
                      type="text"
                      placeholder="Country"
                      validators={[]}
                      onInput={addInputHandler}
                      isDisabled={false}
                      value={addFormState.inputs.country.value}
                      valid={addFormState.inputs.country.isValid}
                    />

                    <h5>Country</h5>
                  </div>
                </div>
              </div>
            }
            footer={
              <div className={`${styles.contacts__Add__Modal__Footer}`}>
                <div
                  className={`${styles.contacts__Add__Modal__Footer__Button}`}
                >
                  <Button
                    size="default"
                    onClick={addContactHandler}
                    disabled={!addFormState.isValid}
                  >
                    ADD
                  </Button>
                </div>
                <div
                  className={`${styles.contacts__Add__Modal__Footer__Button}`}
                >
                  <Button size="inverse" onClick={() => setIsAdd(false)}>
                    CLOSE
                  </Button>
                </div>
              </div>
            }
          ></Modal>

          <Modal
            show={deleteConfirmation === true}
            onCancel={() => setDeleteConfirmation(false)}
            className={`${styles.contacts__Add__Modal}`}
            header={
              <div className={`${styles.contacts__Add__Modal__Header}`}>
                <h2>Are you sure?</h2>
              </div>
            }
            footer={
              <div className={`${styles.contacts__Add__Modal__Footer}`}>
                <div
                  className={`${styles.contacts__Add__Modal__Footer__Button}`}
                >
                  <Button size="danger" onClick={deleteContactHandler}>
                    DELETE
                  </Button>
                </div>
                <div
                  className={`${styles.contacts__Add__Modal__Footer__Button}`}
                >
                  <Button
                    size="inverse"
                    onClick={() => setDeleteConfirmation(false)}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            }
          ></Modal>

          <div className={`${styles.contacts__Container__Header}`}>
            <div className={`${styles.contacts__Container__Header__Segment}`}>
              <div className={`${styles.contacts__Container__Header__Filter}`}>
                <img src="/images/search-icon.svg" />
                <Input
                  id="search"
                  element="input"
                  type="text"
                  placeholder="Search"
                  label="NA"
                  validators={[]}
                  onInput={searchInputHandler}
                  onTyped={searchedContacts}
                  value={searchFormState.inputs.search.value}
                  valid={searchFormState.inputs.search.isValid}
                />
              </div>
              <div className={`${styles.contacts__Container__Header__Sort}`}>
                <Select
                  id="sort"
                  options={[
                    {
                      name: "Customer Lastname (Ascending)",
                      value: "lastNameAsc",
                    },
                    {
                      name: "Customer Lastname (Descending)",
                      value: "lastNameDesc",
                    },
                    {
                      name: "Customer Firstname (Ascending)",
                      value: "firstNameAsc",
                    },
                    {
                      name: "Customer Firstname (Descending)",
                      value: "firstNameDesc",
                    },
                    {
                      name: "Customer City (Ascending)",
                      value: "cityAsc",
                    },
                    {
                      name: "Customer City (Descending)",
                      value: "cityDesc",
                    },
                    {
                      name: "Customer State (Ascending)",
                      value: "stateAsc",
                    },
                    {
                      name: "Customer State (Descending)",
                      value: "stateDesc",
                    },
                    {
                      name: "Customer Phonenumber (Ascending)",
                      value: "phoneAsc",
                    },
                    {
                      name: "Customer Phonenumber (Descending)",
                      value: "phoneDesc",
                    },
                  ]}
                  onSelect={searchSelectHandler}
                  label="NA"
                  returnSelection={sortedContacts}
                  value={searchFormState.inputs.sort.value}
                  valid={searchFormState.inputs.sort.isValid}
                />
              </div>
            </div>
            <div className={`${styles.contacts__Container__Header__Segment}`}>
              <div className={`${styles.contacts__Container__Header__Add}`}>
                <Button type="button" onClick={() => setIsAdd(true)}>
                  ADD
                </Button>
              </div>
            </div>
          </div>
          <div className={`${styles.contacts__Container__Body}`}>
            <div className={`${styles.contacts__ListContainer}`}>
              {filteredContacts &&
                filteredContacts.length > 0 &&
                filteredContacts.map((contact) => (
                  <ContactItem
                    contact={contact}
                    selectedContact={selectedContact}
                    onDetailsSelect={onDetailsSelectHandler}
                    key={contact.customer_phonenumber}
                  />
                ))}
            </div>
            <div className={`${styles.contacts__DataContainer}`}>
              {selectedContact && !isLoading && (
                <div className={`${styles.contacts__Data__Body}`}>
                  <div className={`${styles.contacts__Data__Body__Edit}`}>
                    {!isEdit && (
                      <div
                        className={`${styles.contacts__Data__Body__EditIcons}`}
                      >
                        <img
                          src="/images/pencil-icon.svg"
                          onClick={() => setIsEdit(true)}
                        />
                        <img
                          src="/images/recycle-bin-icon.svg"
                          onClick={() => setDeleteConfirmation(true)}
                        />
                      </div>
                    )}
                    {isEdit && (
                      <div
                        className={`${styles.contacts__Data__Body__EditIcons}`}
                      >
                        <img
                          src="/images/check-mark-icon.svg"
                          onClick={updateCustomerData}
                        />
                        <img
                          src="/images/close-icon.svg"
                          onClick={() => setIsEdit(false)}
                        />
                      </div>
                    )}
                  </div>
                  <div className={`${styles.contacts__Data__Body_RowSegment}`}>
                    <div
                      className={`${styles.contacts__Data__Body_RowSegment__Data}`}
                    >
                      {!isEdit && <h3>{formState.inputs.firstname.value}</h3>}

                      {isEdit && (
                        <Input
                          id="firstname"
                          label="NA"
                          element="input"
                          type="text"
                          placeholder={formState.inputs.firstname.value}
                          validators={[]}
                          onInput={inputHandler}
                          isDisabled={false}
                          value={formState.inputs.firstname.value}
                          valid={formState.inputs.firstname.isValid}
                        />
                      )}

                      <h5>First Name</h5>
                    </div>

                    <div
                      className={`${styles.contacts__Data__Body_RowSegment__Data}`}
                    >
                      {!isEdit && <h3>{formState.inputs.lastname.value}</h3>}

                      {isEdit && (
                        <Input
                          id="lastname"
                          label="NA"
                          element="input"
                          type="text"
                          placeholder={formState.inputs.lastname.value}
                          validators={[]}
                          onInput={inputHandler}
                          isDisabled={false}
                          value={formState.inputs.lastname.value}
                          valid={formState.inputs.lastname.isValid}
                        />
                      )}

                      <h5>Last Name</h5>
                    </div>
                  </div>
                  <div className={`${styles.contacts__Data__Body_RowSegment}`}>
                    <div
                      className={`${styles.contacts__Data__Body_RowSegment__Data}`}
                    >
                      {!isEdit && <h3>{formState.inputs.email.value}</h3>}

                      {isEdit && (
                        <Input
                          id="email"
                          label="NA"
                          element="input"
                          type="text"
                          placeholder={formState.inputs.email.value}
                          validators={[]}
                          onInput={inputHandler}
                          isDisabled={false}
                          value={formState.inputs.email.value}
                          valid={formState.inputs.email.isValid}
                        />
                      )}

                      <h5>Email</h5>
                    </div>

                    <div
                      className={`${styles.contacts__Data__Body_RowSegment__Data}`}
                    >
                      {!isEdit && <h3>{formState.inputs.comments.value}</h3>}

                      {isEdit && (
                        <Input
                          id="comments"
                          label="NA"
                          element="input"
                          type="text"
                          placeholder={formState.inputs.comments.value}
                          validators={[]}
                          onInput={inputHandler}
                          isDisabled={false}
                          value={formState.inputs.comments.value}
                          valid={formState.inputs.comments.isValid}
                        />
                      )}

                      <h5>Comments</h5>
                    </div>
                  </div>
                  <div className={`${styles.contacts__Data__Body_RowSegment}`}>
                    <div
                      className={`${styles.contacts__Data__Body_RowSegment__Data}`}
                    >
                      {!isEdit && <h3>{formState.inputs.address.value}</h3>}

                      {isEdit && (
                        <Input
                          id="address"
                          label="NA"
                          element="input"
                          type="text"
                          placeholder={formState.inputs.address.value}
                          validators={[]}
                          onInput={inputHandler}
                          isDisabled={false}
                          value={formState.inputs.address.value}
                          valid={formState.inputs.address.isValid}
                        />
                      )}

                      <h5>Address</h5>
                    </div>

                    <div
                      className={`${styles.contacts__Data__Body_RowSegment__Data}`}
                    >
                      {!isEdit && <h3>{formState.inputs.city.value}</h3>}

                      {isEdit && (
                        <Input
                          id="city"
                          label="NA"
                          element="input"
                          type="text"
                          placeholder={formState.inputs.city.value}
                          validators={[]}
                          onInput={inputHandler}
                          isDisabled={false}
                          value={formState.inputs.city.value}
                          valid={formState.inputs.city.isValid}
                        />
                      )}

                      <h5>City</h5>
                    </div>
                  </div>
                  <div className={`${styles.contacts__Data__Body_RowSegment}`}>
                    <div
                      className={`${styles.contacts__Data__Body_RowSegment__Data}`}
                    >
                      {!isEdit && <h3>{formState.inputs.state.value}</h3>}

                      {isEdit && (
                        <Input
                          id="state"
                          label="NA"
                          element="input"
                          type="text"
                          placeholder={formState.inputs.state.value}
                          validators={[]}
                          onInput={inputHandler}
                          isDisabled={false}
                          value={formState.inputs.state.value}
                          valid={formState.inputs.state.isValid}
                        />
                      )}

                      <h5>State</h5>
                    </div>

                    <div
                      className={`${styles.contacts__Data__Body_RowSegment__Data}`}
                    >
                      {!isEdit && <h3>{formState.inputs.country.value}</h3>}

                      {isEdit && (
                        <Input
                          id="country"
                          label="NA"
                          element="input"
                          type="text"
                          placeholder={formState.inputs.country.value}
                          validators={[]}
                          onInput={inputHandler}
                          isDisabled={false}
                          value={formState.inputs.country.value}
                          valid={formState.inputs.country.isValid}
                        />
                      )}

                      <h5>Country</h5>
                    </div>
                  </div>
                </div>
              )}

              {selectedContact && !isLoading && <Divider variant="middle" />}
              {selectedContact && !isLoading && (
                <div className={`${styles.contacts__Data__Outbound}`}>
                  <form
                    className={`${styles.contacts__Data__Outbound__Controls}`}
                    onSubmit={makeDirectOutboundCallHandler}
                  >
                    <div
                      className={`${styles.contacts__Data__Outbound__Controls__Segment}`}
                    >
                      <div
                        className={`${styles.contacts__Data__Outbound__Controls__Queue}`}
                      >
                        <Select
                          id="queue"
                          label="Outbound Queue"
                          options={queueOptions}
                          onSelect={outboundSelectHandler}
                          value={outboundFormState.inputs.queue.value}
                          valid={outboundFormState.inputs.queue.isValid}
                        />
                      </div>
                    </div>
                    <div
                      className={`${styles.contacts__Data__Outbound__Controls__Segment}`}
                    >
                      <Button
                        type="submit"
                        disabled={!outboundFormState.isValid}
                      >
                        CALL
                      </Button>
                    </div>
                  </form>
                </div>
              )}

              {!selectedContact && !isLoading && (
                <div className={`${styles.contacts__NoDataContainer}`}>
                  <h2>No Contacts</h2>
                </div>
              )}

              {isLoading && (
                <div className={`${styles.contacts__Loading}`}>
                  <Loading />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {props?.auth === false && (
        <div className={`${styles.contacts__NoAuthContainer}`}>
          <h2>Not Authenticated!</h2>
        </div>
      )}
    </>
  );
};

export default Contacts;
